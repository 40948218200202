import { Component, OnInit } from '@angular/core';
import {
    NgbModal,
    ModalDismissReasons,
    NgbCalendar,
    NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from '../notification.service';
import { ReportService } from '../reports/report.service';

@Component({
    selector: 'app-offic-staff',
    templateUrl: './offic-staff.component.html',
    styleUrls: ['./offic-staff.component.scss'],
})
export class OfficStaffComponent implements OnInit {
    closeResult = '';
    cycle: any;
    cycle_year: any;
    updated_at: any;
    start_date: any;
    cycle_id: any;
    end_date: any;
    cycle_no: any;
    meeting_date: NgbDateStruct;
    date: { year: number; month: number };
    office_staff_dialogue_sheet = {
        dialogue_sheet_type_id: 4,
        id: '',
        data: [
            {
                question:
                    'Interesting points to work on (include reference to diagnosis report)',
                answers: {
                    Row1Col1: '',
                    Row2Col1: '',
                    Row3Col1: '',
                    Row4Col1: '',
                    Row5Col1: '',
                    Row6Col1: '',
                    Row7Col1: '',
                    Row8Col1: '',
                    Row9Col1: '',
                    Row10Col1: '',
                },
            },
            {
                question: 'Possible causes of these points',
                answers: {
                    Row1Col2: '',
                    Row2Col2: '',
                    Row3Col2: '',
                    Row4Col2: '',
                    Row5Col2: '',
                    Row6Col2: '',
                    Row7Col2: '',
                    Row8Col2: '',
                    Row9Col2: '',
                    Row10Col2: '',
                },
            },
            {
                question:
                    'Inputs or learning from the industry or other vessels',
                answers: {
                    Row1Col3: '',
                    Row2Col3: '',
                    Row3Col3: '',
                    Row4Col3: '',
                    Row5Col3: '',
                    Row6Col3: '',
                    Row7Col3: '',
                    Row8Col3: '',
                    Row9Col3: '',
                    Row10Col3: '',
                },
            },
            {
                question:
                    'Things crew members/Office Staff can do to address these points',
                answers: {
                    Row1Col4: '',
                    Row2Col4: '',
                    Row3Col4: '',
                    Row4Col4: '',
                    Row5Col4: '',
                    Row6Col4: '',
                    Row7Col4: '',
                    Row8Col4: '',
                    Row9Col4: '',
                    Row10Col4: '',
                },
            },
        ],
    };

    is_update = false;
    is_success_shown = false;

    constructor(
        private reportService: ReportService,
        private spinner: NgxSpinnerService,
        private modalService: NgbModal,
        private calendar: NgbCalendar,
        private notifyService: NotificationService
    ) {}

    ngOnInit(): void {
        this.spinner.show();
        this.meeting_date = this.calendar.getToday();
        this.cycle = JSON.parse(sessionStorage.getItem('current_cycle_id'));
        this.cycle_year = moment(this.cycle.start_date).format('yyyy');
        this.start_date = moment(this.cycle.start_date).format('LL');
        this.updated_at = moment(this.cycle.updated_at).format('YYYY-MM-DD');
        this.end_date = moment(this.cycle.end_date).format('LL');
        this.cycle_id = this.cycle.id;
        this.cycle_no = this.cycle.cycle_no;
        this.reportService.get_all_dialogue_sheet().subscribe((res: any) => {
            let office_staff_dialogue_sheet = res.data.find(
                (x) => x.dialogue_sheet_type_id === 4
            );

            if(office_staff_dialogue_sheet){
                this.is_update = true;
                this.office_staff_dialogue_sheet.id = office_staff_dialogue_sheet.id;
                this.office_staff_dialogue_sheet.data = office_staff_dialogue_sheet.data
            }
            this.spinner.hide();
        });
    }

    open(content) {
        this.modalService
            .open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg' })
            .result.then(
                (result) => {
                    this.closeResult = `Closed with: ${result}`;
                },
                (reason) => {
                    this.closeResult = `Dismissed ${this.getDismissReason(
                        reason
                    )}`;
                }
            );
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    saveOfficeDialogueSheet() {
        this.spinner.show();
        this.reportService
            .create_dialog_sheet(this.office_staff_dialogue_sheet)
            .subscribe((res: any) => {
                if (res.data) {
                    this.notifyService.showSuccess(
                        'Office Staff Dialogue Sheet Saved Successfully',
                        'Success'
                    );
                }
                this.spinner.hide();
            });
    }

    updateOfficeDialogueSheet() {
        this.spinner.show();
        this.reportService
            .update_dialog_sheet(this.office_staff_dialogue_sheet)
            .subscribe((res: any) => {
                if (res.data) {
                    this.notifyService.showSuccess(
                        'Office Staff Dialogue Sheet Updated Successfully',
                        'Success'
                    );
                }
                this.spinner.hide();
            });
    }
}

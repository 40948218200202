import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { LoginReq, LoginResp } from './login.modal';

@Injectable({
    providedIn: 'root',
})
export class LoginService {
    constructor(
        private http: HttpClient,
        @Inject('apiBase') private apiBase: string
    ) {}

    login(data: LoginReq): Observable<LoginResp> {
        return this.http.post<LoginResp>(this.apiBase + 'auth/login', data);
    }

    logOut(): Observable<any> {
        return this.http.post(this.apiBase + 'auth/logout', null);
    }

    forgotPassword(email): Observable<any> {
        return this.http.post(this.apiBase + 'auth/password/email', email);
        
    }
    
    getIPAddr(): Observable<any> {
        return this.http.get("http://api.ipify.org/?format=json");      
    }

    authenticated(): Observable<any> {
        const token = sessionStorage.getItem('accessToken');
        const pinAuthenticated = sessionStorage.getItem('pinAuthenticated');
        if (token && token != null && token !== '' && pinAuthenticated === 'true') {
                return of({ authenticated: true, pinAuthenticated: true });
        } else if (token && token != null && token !== '' && pinAuthenticated != 'true'){
            return of({ authenticated: true, pinAuthenticated: false });
        }
        else {
            return of({ authenticated: false, pinAuthenticated: false });
        }
    }
}

import { Component, OnInit } from '@angular/core';
import { SdpFormService } from './../sdp-form/sdp-form.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { NotificationService } from '../notification.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-view-sdpform',
  templateUrl: './view-sdpform.component.html',
  styleUrls: ['./view-sdpform.component.scss']
})
export class ViewSdpformComponent implements OnInit {

   public sdp_data:any=[];
   public sdp_data_two:any=[];
   public sdp_data_three:any=[];
   public cycle:any=[];
   public cycle_year:any=[];
   public updated_at:any=[];
   public start_date:any=[];
   public cycle_id:any=[];
   public end_date:any=[];

  constructor(    private sdp_form: SdpFormService) { }
     
  ngOnInit(): void {
       


            
  	       this.cycle = JSON.parse(sessionStorage.getItem('current_cycle_id'));
        if(this.cycle)
        {
           this.cycle_year =  moment(this.cycle.start_date).format('yyyy');
           this.start_date =  moment(this.cycle.start_date).format('MMMM-DD-YYYY');
           this.updated_at =  moment(this.cycle.updated_at).format('YYYY-MM-DD');
           this.end_date =  moment(this.cycle.end_date).format('MMMM-DD-YYYY');
           this.cycle_id =  this.cycle.id
         }else{
            this.cycle_year =  '';
            this.start_date =  '';
            this.updated_at =  '';
            this.end_date =  '';
            this.cycle_id =  '';
         }


  	   this.sdp_form.getsdp_recent().subscribe((data: any) => {

  	   	this.sdp_data = data.data[0];
  	   	this.sdp_data_two = data.data[1];
  	   	this.sdp_data_three = data.data[2];
    

  	   });


 
  }


}

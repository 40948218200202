import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { HomeService } from '../home/home.service';
import { NotificationService } from '../notification.service';
@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {

    vesselName:any;
    email:any;
    public cycle = JSON.parse(sessionStorage.getItem('client'));
    constructor(private homeService: HomeService, private spinner: NgxSpinnerService, private notifyService: NotificationService) {}

    ngOnInit(): void {
      this.vesselName = sessionStorage.getItem('vessel_name').replace(/['"]+/g, '');
      this.email = sessionStorage.getItem('client_email').replace(/['"]+/g, '');;
      // this.spinner.show();
      // this.homeService.gethomeData(this.cycle.id).subscribe((res: any) => {
      //   this.email = res.data.email;
      //   this.spinner.hide();
      // });
    }
}


<div class="home-wrap">
    <div class="container">
        <div class="sub-head-wrap">
            <div class="row">
                <div class="col-6">
                    <ul class="slide-tool">
                        <li [routerLink]="['/home']"><i class="fa fa-angle-left"></i></li>
                    </ul>
                </div>
            </div>
            <h4>Dashboard</h4>
            <div class="row">
                <div class="col-sm-10 offset-sm-1">
                    <form [formGroup]="cycleForm">
                        <div class="cycle-inline form-group form-inline">
                            Cycle Year
                            <select (change)="updateSelectedCycles()" formControlName="cycle_year" class="form-control">
                                <option *ngFor="let cy of cycle_years" [ngValue]="cy.year" >{{cy.year}}</option>
                            </select>
                            Cycle No.
                            <select (change)="updateSelectedCycles()" formControlName="cycle_number" class="form-control">
                                <option *ngFor="let cn of cycle_numbers" [ngValue]="cn.cycle_no" >{{cn.cycle_date}}</option>
                            </select>
                            <button (click)="getDashboardExcel()" class="export-button btn btn-primary btn-sm main-btn">
                                <i class="fa fa-file-excel-o" aria-hidden="true"></i> &nbsp; Export
                            </button>
                        </div>
                    </form>
                    <div class="table-responsive">
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">Vessel</th>
                                    <th scope="col">Start Date</th>
                                    <th scope="col">End Date</th>
                                    <th scope="col">Diagnosis</th>
                                    <th scope="col">Dialogue</th>
                                    <th scope="col">Development</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let cycle of cycle_data">
                                    <td>
                                        {{cycle.name}}
                                    </td>
                                    <td>
                                        {{cycle.start_date | date}}
                                    </td>
                                    <td>
                                        {{cycle.end_date | date}}
                                    </td>
                                    <td>
                                        {{cycle.diagnosis.status}}
                                    </td>
                                    <td>
                                        {{cycle.dialogue.status}}
                                    </td>
                                    <td>
                                        {{cycle.development.status}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
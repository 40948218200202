
<div class="home-wrap">
    <div class="container">
        <div class="sub-head-wrap">
            <div class="row">
                <div class="col-6">
                    <ul class="slide-tool">
                        <li [routerLink]="['/home']"><i class="fa fa-angle-left"></i></li>
                    </ul>
                </div>
            </div>
            <h4>Profile</h4>
            <div class="row">
                <div class="col-sm-10 offset-sm-1">
                    <div class="row">
                        <div class="col-sm-3 mb-3">
                            <span>
                                Vessel Name
                            </span>
                        </div>
                        <div class="col-sm-9 mb-3">
                            {{vesselName}}
                        </div>
                        <div class="col-sm-3 mb-3">
                            <span>
                                Email
                            </span>
                        </div>
                        <div class="col-sm-9 mb-3">
                            {{email}}
                        </div>
                        <div class="col-sm-3 mb-3">
                            <span>
                                Password
                            </span>
                        </div>
                        <div class="col-sm-5 mb-3">
                            ******
                        </div>
                        <div class="col-sm-4 mb-3">
                            <span>
                                <a [routerLink]="['/profile/reset-password']">Reset Password</a>
                            </span>
                        </div>
                        <div class="col-sm-3 mb-3">
                            <span>
                                Pin
                            </span>
                        </div>
                        <div class="col-sm-5 mb-3">
                            ****
                        </div>
                        <div class="col-sm-4 mb-3">
                            <span>
                                <a [routerLink]="['/reset-pin']">Reset Pin</a>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "linear-gradient(45deg, #1a1e1f 0%, #0e6d7c 100%)" type = "square-jelly-box" [fullScreen] = "true"><p style="color: white" > Loading... </p></ngx-spinner>



import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from '../notification.service';
import {DashboardService} from './dashboard.service';
@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {

    cycle_years:any = [];
    cycle_numbers:any = [];
    cycles:any = [];
    cycle_data:any[] = [];
    vessel_name:any;

    cycleForm = this.fb.group({
      cycle_year: [''],
      cycle_number: [''],
    })

    constructor(private dashboardService: DashboardService, private spinner: NgxSpinnerService, private notifyService: NotificationService, public fb: FormBuilder) {}

    ngOnInit(): void {
      this.spinner.show();
      this.vessel_name = JSON.parse(sessionStorage.getItem('vessel_name'));
      this.dashboardService.get_dashboard().subscribe((res: any) => {
        this.cycle_years.push(res.cycleYear);
        let cycleYear = "";

        if( Array.isArray(res.cycles)){
          cycleYear = res.cycles[0].cycle_no
          this.cycle_numbers = res.cycles;
        } else {
          for (let key in res.cycles) {
            this.cycle_numbers.push(res.cycles[key])
          }
          cycleYear = res.cycles[Object.keys(res.cycles)[0]].cycle_no;
        }

        this.cycleForm.patchValue({
          cycle_year: res.cycleYear.year,
          cycle_number: cycleYear
        });
        this.updateSelectedCycles();
        this.spinner.hide();
      });
    }

    updateSelectedCycles(){
      let yearId = this.cycleForm.controls['cycle_year'].value;
      let cycle_number = this.cycleForm.controls['cycle_number'].value;
      this.cycle_data = [];
      this.dashboardService.get_dashboard_with_Params(yearId, cycle_number).subscribe((res: any) => {
        if(this.vessel_name){
          res.vessels.forEach(element => {
            if(element.name === this.vessel_name){
              this.cycle_data.push(element)
            }
          });
        } else {
          this.cycle_data = [...res.vessels];
        }
      });
    }

    getDashboardExcel(){
      let yearId = this.cycleForm.controls['cycle_year'].value;
      let cycle_number = this.cycleForm.controls['cycle_number'].value;
      this.dashboardService.get_dashboard_excel(yearId, cycle_number).subscribe((res: any) => {
        var newBlob = new Blob([res], {type: "application/vnd.ms-excel"})
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(newBlob);
        link.download = "dashboard.xlsx";
        link.click();
      });
    }
}

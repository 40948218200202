
<div class="home-wrap">
    <div class="container">
        <div class="sub-head-wrap">
            <div class="row">
                <div class="col-6">
                    <ul class="slide-tool">
                        <li [routerLink]="['/home']"><i class="fa fa-angle-left"></i></li>
                    </ul>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6 offset-sm-3">
                    <form [formGroup]="resetPassword" (ngSubmit)="submit()">
                        <div class="form-head">
                            <h4>Reset your password</h4>
                            <p>Type in your new password to update your account.</p>
                        </div>
                        <div class="form-group">
                            <label>Old Password* </label>
                            <div class="form-group">
                                <input formControlName="oldPassword" [type]="oldPasswordType ? 'password' : 'text'"
                                    class="form-control" placeholder="old Password">
                                <img (click)="oldPasswordType = !oldPasswordType" src="assets/images/eye.svg" class="img img-fluid toggle-password">
                            </div>
                            <label>New Password*</label>
                            <div class="form-group">
                                <input formControlName="newPassword" [type]="passwordType ? 'password' : 'text'"
                                    class="form-control" placeholder="Password">
                                <img (click)="passwordType = !passwordType" src="assets/images/eye.svg" class="img img-fluid toggle-password">
                            </div>
                            <label>Retype New Password*</label>
                            <div class="form-group">
                                <input formControlName="newPasswordConfirmation" [type]="confirmPasswordType ? 'password' : 'text'"
                                    class="form-control" placeholder="Retype New Password">
                                <img (click)="confirmPasswordType = !confirmPasswordType" src="assets/images/eye.svg" class="img img-fluid toggle-password">
                            </div>
                        </div>
                        <button type="submit" class="btn btn-primary main-btn mr-2">
                            Submit
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div> 
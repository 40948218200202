import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginService } from '../login/login.service';
import { SearchService } from '../search/search.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
	public user_type:string='';
	isCompanyLevel:boolean;	
	locales:any = [];
	selectedLang:any = "";
	languageModal: any = "";
	vesselName:any;
    constructor(private router: Router, private loginService: LoginService, private searchserv: SearchService, private modalService: NgbModal) {}

	ngOnInit(): void {
		this.vesselName = sessionStorage.getItem('vessel_name') != 'null' ? sessionStorage.getItem('vessel_name').replace(/['"]+/g, '') : sessionStorage.getItem('company_name').replace(/['"]+/g, '');
		this.user_type = sessionStorage.getItem('user_type');
		this.isCompanyLevel = JSON.parse(sessionStorage.getItem('vessel_name')) ? false : true;
		this.selectedLang = sessionStorage.getItem("selectedLang") ? sessionStorage.getItem("selectedLang") : "en_US";
		this.searchserv.getLocale().subscribe((res: any) => {
			this.locales = res.data;
		});
	}
	
	logout() {
		// this.loginService.logOut().subscribe((res) => {
		// 	console.log('res', res);
		// }, (error) => {
		// 	console.log('error', error);
		// });
		sessionStorage.removeItem('accessToken');
		sessionStorage.removeItem('userType');
		sessionStorage.removeItem('pinAuthenticated');
		localStorage.removeItem('homeCollection')
		localStorage.removeItem('learningMaterials')

		//console.log('jjjjj',sessionStorage.removeItem('userType'));
		this.router.navigate(['/login']);
	}

	openLanguageModal(content) {
		this.languageModal = content;
        this.modalService
            .open(content, { ariaLabelledBy: 'modal-basic-title', size: 'sm' })
            .result.then(
                (result) => {
                    let a = result;
                }
            );
    }

	confirmLanguage(){
		sessionStorage.setItem("selectedLang", this.selectedLang);
		this.router.navigateByUrl(`/report`).then(
		() => {
			this.router.navigateByUrl(`/home`);
		});
		
		this.modalService.dismissAll();
	}
}

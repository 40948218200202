<nav class="navbar navbar-expand-lg">
	<div class="container">
		<a class="navbar-brand nav-link" [routerLink]="['/home']" routerLinkActive="active"><img src="assets/images/logo-new.png" class="img img-fluid"></a>
		<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown"
			aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
			<span class="navbar-toggler-icon"></span>
		</button>
		<div class="collapse navbar-collapse" id="navbarNavDropdown">
			<ul class="navbar-nav ml-auto">
				<li class="nav-item active">
					<a class="nav-link" [routerLink]="['/home']" routerLinkActive="active">Home</a>

				</li>
				<li class="nav-item">
					<a class="nav-link" [routerLink]="['/search']" routerLinkActive="active">Search</a>
				</li>
				<li class="nav-item">
					<a class="nav-link" [routerLink]="['/dashboard']">Dashboard</a>
				</li>
				<li class="nav-item">
					<a class="nav-link" [routerLink]="['/report']">Reports</a>
				</li>
				<li ngbDropdown class="d-inline-block nav-admin">
					<button class="btn" id="dropdownBasic1" ngbDropdownToggle>
						<img src="assets/images/onboard.svg" class="img img-fluid"><span>{{vesselName}}</span></button>

					<div ngbDropdownMenu aria-labelledby="dropdownBasic1">
						<button ngbDropdownItem [routerLink]="['/profile']">Profile</button>
						<button ngbDropdownItem [routerLink]="['/choose']">Change User</button>
						<button *ngIf="!isCompanyLevel" ngbDropdownItem [routerLink]="['/change-cycle']">Cycle Status</button>
						<button ngbDropdownItem (click)="openLanguageModal(language)">Language Selection</button>
						<button ngbDropdownItem (click)="logout()">Logout</button>
					</div>
				</li>
			</ul>
		</div>
	</div>
</nav>

<ng-template #language let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Language</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
			<div class="col-sm-12">
				<select [(ngModel)]="selectedLang" class="form-control" id="selected-lang">
					<option *ngFor="let local of locales" value="{{local.code}}"> {{local.name}}</option>
				</select>
			</div>
			<div class="col-md-6 offset-md-3 mt-top">
				<button (click)="confirmLanguage()" class="btn fill-width btn-primary main-btn">Confirm</button>
			</div>
		</div>
    </div>
</ng-template>
<div class="login-wrap">
	<div class="container">
		<div class="row">
			<div class="col-md-6">
				<div class="left-wrap">
					<img src="assets/images/logo.png" class="img img-fluid">
					<h3>Welcome to Safety Delta</h3>
					<p>This is where you can access all your learning materials and reports.</p>
				</div>
			</div>
			<div class="col-md-6">
				<form [formGroup]="forgotForm">
					<div class="form-head">
						<h4>Forgot your password?</h4>
						<p>Please enter your registered email address and we will send you a link to reset your password.</p>
					</div>
					<div class="form-group">
						<input formControlName="email" type="email" class="form-control"
							placeholder="Email address or username">
						<span
							*ngIf="((forgotForm.controls.email.touched) && (forgotForm.controls.email.errors?.required))"
							class="text-danger"> Please enter email </span>
						<span
							*ngIf="((forgotForm.controls.email.touched) && (forgotForm.controls.email.errors?.pattern))"
							class="text-danger">Please enter valid email</span>
					</div>
					<button (click)="forgotSubmit()" class="btn btn-primary main-btn">Reset Password</button>
					<div class="row mt-3">
						<p>Already have an account? <a [routerLink]="['/login']">Sign In</a></p>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>
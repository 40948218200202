import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pin',
  templateUrl: './pin.component.html',
  styleUrls: ['./pin.component.scss']
})
export class PinComponent implements OnInit {
  createdPin:any;
  retypePin:any;

  userPin:any;
  oldPin:any;

  errorMessage:any;
  constructor(private router: Router) { }

  ngOnInit(): void {
    this.oldPin = localStorage.getItem('mypin');
  }

  onCodeChangedPin(code: string) {
    this.createdPin = code;
  }

  onCodeCompletedPin(code: string) {
    this.createdPin = code;
  }

  onCodeChangedRetypePin(code: string) {
    this.retypePin = code;
  }

  onCodeCompletedRetypePin(code: string) {
    this.retypePin = code;
  }

  onCodeChangedUserPin(code: string) {
    this.userPin = code;
  }

  onCodeCompletedUserPin(code: string) {
    this.userPin = code;
  }

  confirmNewPin(){
    if(this.createdPin === this.retypePin){
      sessionStorage.setItem('user_type', "Onboard_Facilitator");
      sessionStorage.setItem('user_role_id', "2");
      sessionStorage.setItem('pinAuthenticated', "true");
      localStorage.setItem('mypin', this.createdPin);
      this.router.navigate(['/home']);
    } else {
      this.errorMessage = "PIN does not match!"
    }
  }

  confirmOldPin(){
    if(this.userPin === this.oldPin){
      sessionStorage.setItem('user_type', "Onboard_Facilitator");
      sessionStorage.setItem('user_role_id', "2");
      sessionStorage.setItem('pinAuthenticated', "true");
      this.router.navigate(['/home']);
    } else {
      this.errorMessage = "PIN does not match!"
    }
  }

  resetPin(){
    this.errorMessage ="";
    this.oldPin = "";
    localStorage.removeItem('mypin')
  }

}

import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from './login.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import * as CryptoJS from 'crypto-js';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
    public loginForm: FormGroup;
    public passwordType = true;
    public rememberMe: boolean;
    private email: string = null;
    private password: string = null;
    private deviceInfo: any = null;
    private deviceip: string = null;
    public pin: string = null;
    public cpin: string = null;
    isDeactivated:boolean = false;
    pin_error = false;
    cpin_error = false;

    constructor(
        private fb: FormBuilder,
        private router: Router,
        private loginService: LoginService,
        private toastr: ToastrService,
        private spinner: NgxSpinnerService,
        private deviceService: DeviceDetectorService
    ) {
        this.epicFunction();
        this.getIPAddress();
    }

    ngOnInit(): void {
        this.initLoginForm();
    }

    getIPAddress(): void {
        this.loginService.getIPAddr().subscribe((res: any) => {
            this.deviceip = res.ip;
            this.initLoginForm();
            //console.log('res',this.deviceip);
        });
    }

    initLoginForm(): void {
        if(localStorage.getItem('rememberMe') === 'true'){
            this.getUser();
        }

        this.loginForm = this.fb.group({
            email: [
                this.email,
                [
                    Validators.required,
                    Validators.pattern(
                        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    ),
                ],
            ],
            password: [this.password, Validators.required],
            device_id: this.deviceip,
            device_model:
                this.deviceInfo.deviceType +
                '-' +
                this.deviceInfo.os_version +
                '-' +
                this.deviceInfo.browser +
                '- version-' +
                this.deviceInfo.browser_version,
        });
    }

    login() {
        for (const c of Object.keys(this.loginForm.controls)) {
            this.loginForm.controls[c].markAsTouched();
        }

        this.spinner.show();
        if (this.loginForm.valid) {
            this.loginService.login(this.loginForm.value).subscribe(
                (res) => {

                    if(JSON.stringify(res.data.client.is_deactivated) === '1'){
                        this.isDeactivated = true;
                    } else {

                        if (this.rememberMe) {
                            this.rememberMeChecked();
                            localStorage.setItem('rememberMe', 'true')
                        } else {
                            localStorage.setItem('rememberMe', 'false')
                        }

                        sessionStorage.setItem('user_type', 'Onboard_Facilitator');
                        sessionStorage.setItem(
                            'accessToken',
                            res.meta.token.access_token
                        );
                        sessionStorage.setItem(
                            'client',
                            JSON.stringify(res.data.client)
                        );
                        sessionStorage.setItem('userType', res.meta.token.type);
                        sessionStorage.setItem(
                            'current_cycle_id',
                            JSON.stringify(res.data.current_cycle)
                        );
                        sessionStorage.setItem(
                            'vessel_name',
                            JSON.stringify(res.data.vessel_name)
                        );
                        sessionStorage.setItem(
                            'company_name',
                            JSON.stringify(res.data.client.name)
                        );
                        sessionStorage.setItem(
                            'client_id',
                            JSON.stringify(res.data.client.id)
                        );
                        sessionStorage.setItem(
                            'client_email',
                            JSON.stringify(res.data.email)
                        );
                        this.router.navigate(['/generate-pin']);
                    }
                    
                },
                (error) => {
                    if (error.message.split(':')[2] === ' 0 Unknown Error') {
                        this.toastr.error('Server is not responding');
                        return;
                    }
                    if (error.error && error.error.error) {
                        this.toastr.error(error.error.error.message);
                    }
                }
            );
        }
        this.spinner.hide();
    }

    private rememberMeChecked() {
        const userData =
            this.loginForm.get('email').value +
            '&&' +
            this.loginForm.get('password').value;
        localStorage.setItem(
            'identity',
            CryptoJS.AES.encrypt(userData, '123S@cUr!#^')
        );
    }

    epicFunction() {
        // console.log('hello `Home` component');
        this.deviceInfo = this.deviceService.getDeviceInfo();
        const isMobile = this.deviceService.isMobile();
        const isTablet = this.deviceService.isTablet();
        const isDesktopDevice = this.deviceService.isDesktop();
    }

    getUser() {
        const data = CryptoJS.AES.decrypt(
            localStorage.getItem('identity'),
            '123S@cUr!#^'
        ).toString(CryptoJS.enc.Utf8);
        this.email = data.split('&&')[0];
        this.password = data.split('&&')[1];
        this.rememberMe = true;
    }
}


<div class="home-wrap">
    <div class="container">
        <div class="sub-head-wrap">
            <div class="row">
                <div class="col-6">
                    <ul class="slide-tool">
                        <li [routerLink]="['/home']"><i class="fa fa-angle-left"></i></li>
                    </ul>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6 offset-sm-3">
                    <form>
                        <div class="form-head">
                            <h4>Reset PIN</h4>
                            <p>
                                You will use this PIN to access confidential
                                learning materials on the platform. <br />
                                Please nominate a 4-digit PIN.
                            </p>
                        </div>
                        <div class="pin-div">
                            <div class="form-group">
                              <h6>PIN</h6>
                                <code-input
                                    [isCodeHidden]="true"
                                    [codeLength]="4"
                                    (codeChanged)="onCodeChangedPin($event)"
                                    (codeCompleted)="onCodeCompletedPin($event)"
                                >
                                </code-input>
                            </div>
                            <div class="form-group">
                                <h6>Retype PIN</h6>
                                <code-input
                                    [isCodeHidden]="true"
                                    [codeLength]="4"
                                    (codeChanged)="onCodeChangedRetypePin($event)"
                                    (codeCompleted)="onCodeCompletedRetypePin($event)"
                                >
                                </code-input>
                            </div>
                        </div>
                        <p class="error">{{errorMessage}}</p>
                        <button (click)="confirmNewPin()" class="btn btn-primary main-btn">Confirm</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div> 
import { Injectable, Inject } from '@angular/core';
import { HttpClient,HttpHeaders  } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

       constructor(
        private http: HttpClient,
        @Inject('apiBase') private apiBase: string
    ) {}


    private httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + sessionStorage.getItem('accessToken'),
      })
    };
    cycle =  JSON.parse(sessionStorage.getItem('current_cycle_id'));

    get_report_pdf(): Observable<any>{
      const httpOptions = {
        responseType: 'blob' as 'json',
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + sessionStorage.getItem('accessToken'),
        })
      };

      return this.http.get(`${this.apiBase}reports/${this.cycle.id}/crew-safety-diagnosis-file`, httpOptions);
    }

    get_all_dialogue_sheet(): Observable<any> {
      return this.http.get(`${this.apiBase}cycles/${this.cycle.id}/dialogue-sheets`, this.httpOptions).pipe(catchError(this.handleError));
    }

    get_dialogue_sheet_by_id(dialogueSheetId): Observable<any> {
      return this.http.get(`${this.apiBase}cycles/${this.cycle.id}/dialogue-sheets/${dialogueSheetId}`, this.httpOptions).pipe(catchError(this.handleError));
    }

    create_dialog_sheet(dialogSheet): Observable<any> {
      return this.http.post(`${this.apiBase}cycles/${this.cycle.id}/dialogue-sheets`, dialogSheet, this.httpOptions).pipe(catchError(this.handleError));
    }

    update_dialog_sheet(dialogSheet): Observable<any> {
      let data = {data:dialogSheet.data }
      return this.http.put(`${this.apiBase}cycles/${this.cycle.id}/dialogue-sheets/${dialogSheet.id}/update`, data, this.httpOptions).pipe(catchError(this.handleError));
    }

    get_dialogue_sheet_pdf(id): Observable<any>{
      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + sessionStorage.getItem('accessToken'),
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*'
        }),
        responseType: 'blob' as 'json'
      };

      return this.http.get(`${this.apiBase}cycles/${this.cycle.id}/dialogue-sheets/${id}/generate`, httpOptions).pipe(catchError(this.handleError));;
    }

    get_sdp_report_file(): Observable<any>{
      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + sessionStorage.getItem('accessToken'),
        }),
        responseType: 'blob' as 'json'
      };

      return this.http.get(`${this.apiBase}reports/${this.cycle.id}/development-plan/generate`, httpOptions).pipe(catchError(this.handleError));;
    }

    getsdp_recent() {
      const current_id = JSON.parse(
          sessionStorage.getItem('current_cycle_id')
      );
      const id = current_id.id;
      const httpOptions = {
          headers: new HttpHeaders({
              Authorization:
                  'Bearer ' + sessionStorage.getItem('accessToken'),
          }),
      };

      return this.http.get(
          `${this.apiBase}reports/${id}/development-plan`,
          httpOptions
      );
  }


    handleError(error) {
      //window.location.href = "/login";
      let errorMessage = '';
      if (error.error instanceof ErrorEvent) {
        errorMessage = `Error: ${error.error.message}`;
  
      } else {
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      }
      window.alert(errorMessage);
      return throwError(errorMessage);
    }
}

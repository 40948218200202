import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ResetPasswordService {
  
  constructor(
      private http: HttpClient,
      @Inject('apiBase') private apiBase: string
  ) {}

  resetPassword(data) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + sessionStorage.getItem('accessToken'),
      })
    };

    return this.http.post(
        `${this.apiBase}auth/password/change`,data,
        httpOptions
    );
  }
}

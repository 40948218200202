<div class="login-wrap">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="left-wrap">
                    <img src="assets/images/logo.png" class="img img-fluid" />
                    <h3>Welcome to Safety Delta</h3>
                    <p>
                        This is where you can access all your learning materials
                        and reports.
                    </p>
                </div>
            </div>
            <div class="col-md-6">
                <form [formGroup]="resetForm" (ngSubmit)="onSubmit()">
                    <div class="form-head">
                        <h4>Reset your password</h4>
                        <p>Type in your new password to update your account.</p>
                    </div>
                    <div class="form-group">
                        <input
                            type="hidden"
                            formControlName="token"
                            class="form-control"
                            value="token"
                        />

                        <input
                            type="hidden"
                            formControlName="email"
                            class="form-control"
                            value="email"
                        />

                        <input formControlName="password" [type]="passwordType ? 'password' : 'text'"
							class="form-control" placeholder="Password">
						<img (click)="passwordType = !passwordType" src="assets/images/eye.svg" class="img img-fluid toggle-password">
                    </div>
                    <div class="form-group">
                        <input formControlName="password_confirmation" [type]="passwordTypeConfirm ? 'password' : 'text'"
							class="form-control" placeholder="Password">
						<img (click)="passwordTypeConfirm = !passwordTypeConfirm" src="assets/images/eye.svg" class="img img-fluid toggle-password">
                    </div>
                    <!--     <button type="submit" class="btn btn-primary main-btn"  >Reset Password</button> -->
                    <button type="submit" class="btn btn-primary main-btn mr-2">
                        Reset Password
                    </button>
                </form>     
            </div>
        </div>
    </div>
</div>

import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

@Injectable({
    providedIn: 'root',
})
export class SearchService {
    httpOptions = {
        headers: new HttpHeaders({
            Authorization: 'Bearer ' + sessionStorage.getItem('accessToken'),
        }),
    };

    constructor(
        private http: HttpClient,
        public router: Router,
        @Inject('apiBase') private apiBase: string
    ) {}

    getTags() {
        return this.http.get(`${this.apiBase}lessons/tags`, this.httpOptions);
    }

    getLocale() {
        return this.http.get(`${this.apiBase}config/locales`, this.httpOptions);
    }

    gethomeData(id) {
        //console.log('jjjjjjj',`${this.apiBase}clients/${id}`)
        return this.http
            .get(`${this.apiBase}clients/${id}`, this.httpOptions)
            .pipe(catchError(this.handleError));
    }

    getLessons(role, tags, sel_lang, keyword, isStartup) {
        let options = {
            headers: new HttpHeaders({
                Authorization:
                    'Bearer ' + sessionStorage.getItem('accessToken'),
            }),
        };
        let queryTags = '';
        let queryRoles = '';
        let keyWordTag = '';
        tags.forEach((element) => {
            queryTags = '&tags[]=' + element + queryTags;
        });
        role.forEach((element) => {
            queryRoles = '&roles[]=' + element + queryRoles;
        });
        keyWordTag = keyword
            ? '&keyword=' + keyword
            : !keyword && isStartup
            ? '&keyword='
            : '';
        return this.http
            .get(
                `${this.apiBase}package-items?source=web${queryRoles}&locale=${sel_lang}${keyWordTag}${queryTags}`,
                options
            )
            .map((response:any) => {
                return response;
            })
            .catch(this.handleError);
    }

    handleError(error) {
        let errorArray = [];
        // //window.location.href = "/login";
        // let errorMessage = '';
        // if (error.error instanceof ErrorEvent) {
        //     errorMessage = `Error: ${error.error.message}`;
        // } else {
        //     errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        // }
        // window.alert(errorMessage);
        errorArray.push(error);
        return errorArray;
    }
}

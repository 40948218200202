
<div class="home-wrap">
    <div class="container">
        <div class="sub-head-wrap">
            <app-previous-route></app-previous-route>
            <div *ngIf="loaded" class="row">
                <div class="col-sm-12">
                    <div class="row">
                        <div class="col">
                            <div class="act-img">
                                <img *ngIf="checkProperty('thumbnail_path')" :src="lesson.thumbnail_path"
                                    class="img img-fluid" style="height: 60px; width: 60px;">
                                <a *ngIf="subLessons.length > 0 && !isFullScreen" style="float: right;" (click)="isFullScreen = !isFullScreen"> <i class="fa fa-expand" style="color: #fff;"></i></a>
                                <a *ngIf="subLessons.length > 0 && isFullScreen" style="float: right;" (click)="isFullScreen = !isFullScreen"> <i class="fa fa-compress" style="color: #fff;"></i></a>
                            </div>
                            <div class="act-detail">
                                <h2 *ngIf="lesson.title">{{getTitle()}}</h2>
                                <div *ngFor="let section of lesson.sections" class="row">
                                    <div *ngFor="let content of lesson.texts" class="col-sm-12">
                                        <div *ngIf="section.id == content.parent_id">
                                            <div *ngIf="checkIfSectionShown(content.parent_id)">
                                                <div *ngIf="checkIfLocked(content.parent_id, 1)" class="backdrop">
                                                    <div class="row locked-div">
                                                        <div class="col-sm-6 offset-sm-3 align-center">
                                                            <h1><i class="fa fa-lock" aria-hidden="true"></i></h1>
                                                            <h4>Content is locked. Please enter PIN to view.</h4>
                                                        </div>
                                                        <div class="col-sm-6 offset-sm-3">
                                                            <div class="input-group mb-3">
                                                                <input (keyup)="handleChange($event)" type="password" class="form-control" placeholder="Enter Pin">
                                                                <div class="input-group-append"> 
                                                                    <button (click)="checkPin(content.parent_id, 1)" class="btn btn-outline-secondary locked-btn" type="button"><i class="fa fa-unlock" aria-hidden="true"></i>
                                                                        Submit
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="content-div" [innerHtml]="getContent(content.element_type)"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngFor="let file of lesson.files" class="col-sm-12" [ngClass]="{'mt-top-file' : checkIfLocked(file.parent_id, 2)}">
                                        <div *ngIf="section.id == file.parent_id">
                                            <div *ngIf="checkIfSectionShown(file.parent_id)">
                                                <div *ngIf="checkIfLocked(file.parent_id, 2)" class="backdrop">
                                                    <div class="row locked-div">
                                                        <div class="col-sm-6 offset-sm-3 align-center">
                                                            <h1><i class="fa fa-lock" aria-hidden="true"></i></h1>
                                                            <h4>Content is locked. Please enter PIN to view.</h4>
                                                        </div>
                                                        <div class="col-sm-6 offset-sm-3">
                                                            <div class="input-group mb-3">
                                                                <input (keyup)="handleChange($event)" type="password" class="form-control" placeholder="Enter Pin">
                                                                <div class="input-group-append"> 
                                                                    <button (click)="checkPin(file.parent_id, 2)" class="btn btn-outline-secondary locked-btn" type="button"><i class="fa fa-unlock" aria-hidden="true"></i>
                                                                        Submit
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="activity-wrap-lessons activity-box" id="activity-box">
                                                    <a (click)="openFilePreview(file.element_type, file.element_type,content)">
                                                        <div class="row">
                                                            <div class="col-md-2 col-4">
                                                                <div class="act-img">
                                                                    <img src="{{file.element_type[0].data.itemfile.thumbnail_path || 'https://green-jakobsen.s3.ap-southeast-1.amazonaws.com/sitemap/19/images/VO6WzyDhFrnGv3up3fxLRhrTnGEDAYTn3GoV4ok2.png' }}"
                                                                        class="img img-fluid">
                                                                </div>
                                                            </div>
                                                            <div class="col-md-10 col-8">
                                                                <div class="act-detail">
                                                                    <h2>{{getFileName(file.element_type)}}
                                                                    </h2>
                                                                    <p>{{getFileDescription(file.element_type)}}</p>
                                                                    <img src="assets/images/link.svg"
                                                                        class="img img-fluid link-img align right">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4" *ngIf="subLessons.length > 0 && !isFullScreen">
                            <div style="text-align: right;">
                                <button class="btn btn-primary refresh-btn" (click)="getLessonDetail(id)"
                                type="button"><i class="fa fa-refresh"></i></button>
                            </div>
                            <div class="detail-right">
                                <h4>{{getSubgroupName()}}</h4>
                                <div class="row">
                                    <div class="col-md-6 col-6" *ngFor="let sub of subLessons">
                                        <div (click)="navigateToLesson(sub.id)" class="res-box">
                                            <div class="res-img">
                                                <img *ngIf="sub.item.thumbnail_path"
                                                    [src]="sub.item.thumbnail_path" class="img img-fluid">
                                                <img *ngIf="!sub.item.thumbnail_path"
                                                    src="https://green-jakobsen-production.s3-accelerate.amazonaws.com/sitemap/18/images/uBqXvclMgH3if5zMfgaJJMsYrDhjdqFyMCLTg4th.png"
                                                    class="img img-fluid">
                                            </div>
                                            <p>{{getSubLessonTitle(sub.item.titles)}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="linear-gradient(45deg, #1a1e1f 0%, #0e6d7c 100%)"
    type="square-jelly-box" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
</ngx-spinner>
<ng-template #content let-modal class="full-screen">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{fileName}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <iframe allow="fullscreen;" [src]="previewFile" style="width: 100%; height: 100%;">

        </iframe>
    </div>
</ng-template>
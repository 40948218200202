import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from '../notification.service';
import {LessonService} from './lesson.service';
import { DomSanitizer } from '@angular/platform-browser';
import { isArray } from 'util';
import { filter, map } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-lesson',
    templateUrl: './lesson.component.html',
    styleUrls: ['./lesson.component.scss'],
})
export class LessonComponent implements OnInit, OnDestroy  {

  id:any;
  lesson:any;
  loaded:Boolean = false;
  selectedLang = "en_US"
  isLocked = false;
  userRoleId:any ="";
  userPin = "";
  pinContent:any;
  pinFiles:any;
  titles:any;
  relationId:any;
  subLessons:any[] =[];
  subPackages:any[] = [];
  subgroupNames:any[] = [];
  isFullScreen:boolean = false;
  fileTitles:any[] = [];
  contentPermision:any[] = [];
  filePermission:any[] = [];
  typedPin:any = "";
  previewFile:any;
  fileName:any;
  routeChange: Subscription;

  constructor(private lessonService: LessonService, private spinner: NgxSpinnerService, private activatedRoute: ActivatedRoute,
    private router: Router, private notifyService: NotificationService, private modalService: NgbModal, private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.userRoleId = sessionStorage.getItem("user_role_id") ? sessionStorage.getItem("user_role_id") : "3";
    this.userPin = localStorage.getItem("mypin");
    this.activatedRoute.params.subscribe(params => {
      let decodeId = atob(params.id)
      this.id = decodeId;
      this.selectedLang = params.lang;
      this.getLessonDetail(decodeId);
    });

    this.routeChange = this.router.events
    .pipe(
      filter(event => event instanceof NavigationStart),
      map(event => event as NavigationStart),  // appease typescript
    )
    .subscribe(() => {
      this.closeAllLessonModal();
    });

  }

  ngOnDestroy() {
    this.closeAllLessonModal();
    this.routeChange.unsubscribe();
  }

  closeAllLessonModal(){
    if(this.modalService.hasOpenModals()){
      this.modalService.dismissAll();
    }
  }

  processData(data: any){
    if(data.data.package_item_lesson){
      this.lesson = data.data.package_item_lesson.lesson;
      this.titles = data.data.item.titles;
      this.subgroupNames = data.data.item.subgroup_names;
      this.relationId = data.data.item.id;
      this.subPackages = data.data.sub_package_items;
      this.getSubLessons();
      this.getFilesTitles();
      this.checkLockItems();
      this.loaded = true;
    } else {
      this.notifyService.showError(
          "Lesson is empty",
          'Error'
      );
      this.spinner.hide();
    }

  }

  getLessonDetail(id){
    this.loaded = false;
    this.spinner.show();
    this.lessonService.get_lesson_detail(id).subscribe((res: any) => {
      if(!res.hasOwnProperty("error")){
        this.processData(res);
      } else {
        this.spinner.hide();
        this.notifyService.showError(
            res.error.message,
            'Error'
        );
      }
    })
  }

  checkLockItems(){
    this.lesson.sections.forEach(element => {
      this.lesson.files.forEach(file => {
        if(element.id === file.parent_id){
          element.element_type.forEach(elementType => {
            if(elementType.code.code === this.selectedLang){
              if(elementType.data.required_pin){
                this.filePermission.push(
                  {
                    parentId: element.id,
                    isLocked: true,
                    pin: ""
                  }
                )
              } else {
                this.filePermission.push(
                  {
                    parentId: element.id,
                    isLocked: false,
                    pin: ""
                  }
                )
              }
            }
          });
        }
      });

      this.lesson.texts.forEach(lesson => {
        if(element.id === lesson.parent_id){
          element.element_type.forEach(elementType => {
            if(elementType.code.code === this.selectedLang){
              if(elementType.data.required_pin){
                this.contentPermision.push(
                  {
                    parentId: element.id,
                    isLocked: true,
                    pin: ""
                  }
                )
              } else {
                this.contentPermision.push(
                  {
                    parentId: element.id,
                    isLocked: false,
                    pin: ""
                  }
                )
              }
            }
          });
        }
      });
    });
  }

  checkIfLocked(parentId, type) {
    let permissionArray = type == 1 ? this.contentPermision : this.filePermission;
    let index = permissionArray.findIndex(x => x.parentId === parentId);
    return permissionArray[index].isLocked;
  }

  getFilesTitles(){
    this.fileTitles = [];
    this.lesson.files.forEach(element => {
      if(!this.lesson.files.includes(element.title)){
        this.fileTitles.push(element.title);
      }
    });
  }
  
  getSubLessons(){
    this.subLessons = [];
    //this.subLessons =  this.subPackages.filter(item => item.item.parent_id.indexOf(this.relationId) !== -1);
    this.subPackages.forEach(element => {
      if(this.relationId == element.item.parent_id){
        this.subLessons.push(element);
      }
    });
    this.spinner.hide();
  }

  getContent(contents){
    let content = "";
    contents.forEach(element => {
          if(element.code.code === this.selectedLang){
              content = element.data.content;
          }
      });
      return content ? this.sanitizer.bypassSecurityTrustHtml(content) : this.sanitizer.bypassSecurityTrustHtml(contents[0].data.content);
  }

  getFileName(files){
    let file = "";
    files.forEach(element => {
          if(element.code.code === this.selectedLang){
            file = element.data.itemfile ? element.data.itemfile.filename : null;
          }
      });
      return file ? file : files[0].data.itemfile.filename;
  }


  getFileDescription(files){
    let file = "";
    files.forEach(element => {
          if(element.code.code === this.selectedLang){
            file = element.data.itemfile ? element.data.itemfile.description: null;
          }
      });
    return file ? file : files[0].data.itemfile.description;
  }

  getSubLessonTitle(titles){
    let title = "";
    titles.forEach(element => {
          if(element.code.code === this.selectedLang){
            title = element.title ? element.title: null;
          }
      });
    return title ? title : titles[0].title;
  }

  navigateToLesson(id) {
    id = btoa(id);
    this.router.navigate(['lesson', id, this.selectedLang]);
  }


  insertSpaces(string) {
    string = string.replace(/([a-z])([A-Z])/g, '$1 $2');
    string = string.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
    return string;
  }

  checkProperty(prop){
    return this.lesson.hasOwnProperty(prop)
  }

  checkPin(parentId, type){
    let permissionArray = type == 1 ? this.contentPermision : this.filePermission;
    let index = permissionArray.findIndex(x => x.parentId === parentId);

    if(this.typedPin === this.userPin){
      permissionArray[index].isLocked = false;
    }
  }

  handleChange(event){
   this.typedPin = event.target.value;
  }

  getTitle(){
    let title = "";
    this.titles.forEach(element => {
      if(element.code.code === this.selectedLang){
        title = element.title;
      }
    });
    return title;
  }

  getSubgroupName(){
    let name = "";
    this.subgroupNames.forEach(element => {
      if(element.code.code === this.selectedLang){
        name = element.name;
      }
    });
    return name;
  }


  checkIfSectionShown(sectionId){
    let isInPermission = false;
    this.lesson.sections.forEach(element => {
      if(element.id === sectionId){
        element.element_type.forEach(locale => {
          if(locale.code.code === this.selectedLang){
            const checkPermission = obj => obj.staff_role_id == this.userRoleId;
            isInPermission = locale.data.permissions.some(checkPermission) ? true : locale.data.permissions.length == 0 ? true : false;
          }
        });
      }
    });
    return isInPermission;
  }
  
  openFilePreview(file, fileName, content){
    let selectedFile = null;
    if(isArray(file)){
      file.forEach(element => {
        if(element.code.code === this.selectedLang){
          selectedFile = element.data.itemfile.file_path
        }
      });

      if(!selectedFile){
        selectedFile = file[0].data.itemfile.file_path
      }
    }

    this.previewFile = this.sanitizer.bypassSecurityTrustResourceUrl(selectedFile);
    this.fileName = this.getFileName(fileName);
    this.modalService
    .open(content, { ariaLabelledBy: 'modal-basic-title', windowClass: 'full-screen'})
            .result.then(
                (result) => {
                    //this.closeResult = `Closed with: ${result}`;
                },
                (reason) => {
                    // this.closeResult = `Dismissed ${this.getDismissReason(
                    //     reason
                    // )}`;
                }
            );
  }
}

import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class HomeService {
    constructor(
        public router: Router,
        private http: HttpClient,
        @Inject('apiBase') private apiBase: string
    ) {}

    private httpOptions = {
        headers: new HttpHeaders({
            Authorization: 'Bearer ' + sessionStorage.getItem('accessToken'),
        }),
    };

    gethomeData(id) {
        return this.http
            .get(`${this.apiBase}clients/${id}`, this.httpOptions)
            .pipe(catchError(this.handleError));
    }

    getDetails(id) {
        //console.log('httpOptions',sessionStorage.getItem('accessToken'));
        // console.log('url',`${this.apiBase}package-items/40`);

        return this.http
            .get(`${this.apiBase}package-items/${id}`, this.httpOptions)
            .pipe(catchError(this.handleError));
    }

    handleError(error) {
        window.location.href = '/login';
        //this.router.navigate(['/login']);
        //this.router.navigate(['/edit-mapping/'+event]);
        let errorMessage = '';

        if (error.error instanceof ErrorEvent) {
            // client-side error

            errorMessage = `Error: ${error.error.message}`;
        } else {
            // server-side error

            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }

        window.alert(errorMessage);

        return throwError(errorMessage);
    }
}

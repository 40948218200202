import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from '../notification.service';
import {ChangeCycleService} from './change-cycle.service';
@Component({
    selector: 'app-change-cycle',
    templateUrl: './change-cycle.component.html',
    styleUrls: ['./change-cycle.component.scss'],
})
export class ChangeCycleComponent implements OnInit {
    user_type: string = '';
    diagnosis_status:any;
    dialogue_status:any;
    development_status:any;
    cycle:any;

    constructor(private changeCyleService: ChangeCycleService, private spinner: NgxSpinnerService, private notifyService: NotificationService) {}

    ngOnInit(): void {
      this.spinner.show();
      this.cycle =  JSON.parse(sessionStorage.getItem('current_cycle_id'));
      this.changeCyleService.get_cycles().subscribe((res: any) => {
        this.diagnosis_status = res.data.diagnosis_status;
        this.dialogue_status = res.data.dialogue_status;
        this.development_status = res.data.development_status;
        this.spinner.hide();
      });
    }

    updateCycle(ind, val){
      this.spinner.show();
      ind == 0 ? this.diagnosis_status = val : ind == 1 ? this.dialogue_status = val : this.development_status = val;
      let data = {
        diagnosis_status: this.diagnosis_status,
        dialogue_status: this.dialogue_status,
        development_status: this.development_status
      }
      this.changeCyleService.update_cycles(data).subscribe((res: any) => {
        if(res.data){
          this.notifyService.showSuccess(
            'Cycle Status Updated Successfully',
            'Success'
          );
          this.spinner.hide();
        }
      });
    }
}

import { Component, OnInit } from '@angular/core';
import { DetailService } from './detail.service';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html', 
  styleUrls: ['./detail.component.scss']
})
export class DetailComponent implements OnInit {
      public lessons:any=[];
      public lessons_det:any=[];
      public section:any=[];
      public lessons_text:any=[];
      public lessons_file:any=[];
      public related_items:any=[];
  constructor(private detail: DetailService) {  }

  ngOnInit(): void {
    
  	      this.detail.get_lessons().subscribe((res: any=[])=>{
      //console.log(res.lessons);
       this.lessons = res.lessons;
        // console.log(this.lessons);
       this.get_lesson_det(this.lessons[0].id);

    });
    



        
  }
        // get Lesson Details
		onClickMe($id){
		//console.log('id:',$id);
		
			this.get_lesson_det($id);
		}

		 get_lesson_det($id){
				this.section = [];
				this.lessons_det = [];
				this.lessons_text = [];
				this.lessons_file = [];
				this.related_items = [];
			this.detail.get_lesson_detail($id).subscribe((res: any=[])=>{
			// console.log(res);
			this.lessons_det = res.lesson;
			this.section = res.lesson.sections;

			 
        
                  

                 for(let i=0;i<= this.lessons_det.texts.length -1;i++)
                 { 
                 
                  this.lessons_text.push(this.lessons_det.texts[i]);
                 	      
                }


                 //console.log('lessons_text:',this.lessons_text);
  


                 for(let j=0;j<= this.lessons_det.files.length -1;j++)
                 { 
                   if(this.lessons_det.files[j].element_type != ''){
                    this.lessons_file.push(this.lessons_det.files[j]);
                   }
                 	
                 }

                  // console.log('lessons_file',this.lessons_file);


                 for(let j=0;j<= this.lessons_det.related_items.length -1;j++)
                 { 
                 	this.related_items.push(this.lessons_det.related_items[j]);
                 }

                 // console.log('related_items',this.related_items);

                
                

		 	});
		}
  

}

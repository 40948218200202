
<div class="home-wrap">
    <div class="container">
        <div class="sub-head-wrap">
            <div class="row">
                <div class="col-6">
                    <ul class="slide-tool">
                        <li [routerLink]="['/report']"><i class="fa fa-angle-left"></i></li>
                    </ul>
                </div>
            </div>
            <h4>Development Plan</h4>
            <ul class="info">
                <li>Cycle Year: {{cycle_year}}</li>
                <li>Cycle {{cycle_no }}: {{start_date}} - {{end_date}}</li>
            </ul>
        </div>
        <form [formGroup]="sdpForm" (ngSubmit)="onSubmit()">

            <div class="output-wrap">
                <div class="output-head">
                    <div class="row">
                        <div class="col-md-4">
                            <h6>Identify Subject</h6>
                        </div>
                        <div class="col-md-4">
                            <h6>Assemble Learning Package</h6>
                        </div>
                        <div class="col-md-4">
                            <h6>Schedule Learning Package</h6>
                        </div>
                    </div>
                </div>
                <div class="row section-wrap">

                    <div class="col-md-4">
                        <div class="bottom-wrap">
                            <div class="form-group">
                                <label>
                                    <h5>A</h5>Safety Area
                                </label>
                                <select #mySelect (change)='Select_cultivator(mySelect.value,1)' class="form-control"
                                    formControlName="safety_area_one"
                                    [ngClass]="{ 'is-invalid': submitted && f.safety_area_one.errors }">
                                    <option value="" disabled selected hidden>Select safety area</option>
                                    <option *ngFor='let option of safety_area; let i = index' value="{{option.title}}">
                                        {{option.title}}</option>
                                    <!-- <option value="3">Area2</option> -->
                                </select>
                                <div *ngIf="submitted && f.safety_area_one.errors" class="invalid-feedback">
                                    <div *ngIf="f.safety_area_one.errors.required">Safety Area is required</div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label>Cultivator</label>
                                <fieldset [disabled]="!f.safety_area_one.value">
                                    <select class="form-control" #myCultivator formControlName="cultivator1"
                                    (change)='onOptionsSelected(myCultivator.value,1)' formControlName="cultivator1"
                                    [ngClass]="{ 'is-invalid': submitted && f.cultivator1.errors }">
                                    <option value="" disabled selected hidden>Select Cultivator</option>
                                    <option *ngFor='let option of cultivator1; let i = index' [value]="option.title">
                                        {{option.title}}</option>
                                </select>
                                </fieldset>
                                <div *ngIf="submitted && f.cultivator1.errors" class="invalid-feedback">
                                    <div *ngIf="f.cultivator1.errors.required">Cultivator is required</div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label>Subject</label>
                                <fieldset [disabled]="!f.cultivator1.value">
                                    <select class="form-control" #mySubject formControlName="subject1"
                                        (change)='ActivitySelected(mySubject.value,1)'
                                        [ngClass]="{ 'is-invalid': submitted && f.subject1.errors }">
                                        <option value="" disabled selected hidden>Select Subject</option>
                                        <option *ngFor='let option of subject1; let i = index' [value]="option.title">
                                            {{option.title}}</option>
                                    </select>
                                    <div *ngIf="submitted && f.subject1.errors" class="invalid-feedback">
                                        <div *ngIf="f.subject1.errors.required">Subject is required</div>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="bottom-wrap">
                            <div class="form-group ">
                                <label>Activities</label>
                                <ng-multiselect-dropdown [disabled]="!f.subject1.value" [placeholder]="'Select Activity'" [settings]="dropdownSettings"
                                    [data]="dropdownList" (onSelect)="onItemSelect($event)"
                                    (onSelectAll)="onSelectAll($event)" formControlName="activity1"
                                    [ngClass]="{ 'is-invalid': submitted && f.activity1.errors }">
                                </ng-multiselect-dropdown>
                                <div *ngIf="submitted && f.subject1.errors" class="invalid-feedback">
                                    <div *ngIf="f.subject1.errors.required">Activity is required</div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label>Comments</label>
                                <textarea name="" id="" cols="30" rows="5" formControlName="comments1"
                                    placeholder="Enter your comment here" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && f.comments1.errors }"></textarea>
                                <div *ngIf="submitted && f.comments1.errors" class="invalid-feedback">
                                    <div *ngIf="f.comments1.errors.required">Comments is required</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="bottom-wrap">
                            <div class="form-group">
                                <label>Responsible</label>
                                <input type="text" class="form-control" formControlName="responsible1"
                                    placeholder="Enter Name"
                                    [ngClass]="{ 'is-invalid': submitted && f.responsible1.errors }">
                                <div *ngIf="submitted && f.responsible1.errors" class="invalid-feedback">
                                    <div *ngIf="f.responsible1.errors.required">Responsible is required</div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label>Target Date</label>
                                <input type="date" class="form-control" formControlName="target_date1"
                                    placeholder="Select Date"
                                    [ngClass]="{ 'is-invalid': submitted && f.target_date1.errors }">
                                <div *ngIf="submitted && f.target_date1.errors" class="invalid-feedback">
                                    <div *ngIf="f.target_date1.errors.required">Target Date is required</div>
                                </div>
                                <!--  <i class="fa fa-calendar"></i> -->
                            </div>
                            <div class="form-group">
                                <label>Review Date</label>
                                <input type="date" formControlName="review_date1" class="form-control"
                                    placeholder="Select Date"
                                    [ngClass]="{ 'is-invalid': submitted && f.review_date1.errors }">
                                <div *ngIf="submitted && f.review_date1.errors" class="invalid-feedback">
                                    <div *ngIf="f.review_date1.errors.required">Review Date is required</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- two -->
                <div class="row section-wrap">
                    <div class="col-md-4">
                        <div class="bottom-wrap">
                            <div class="form-group">
                                <label>
                                    <h5>B</h5>Safety Area
                                </label>
                                <select #mySelect2 (change)='Select_cultivator(mySelect2.value,2)' class="form-control"
                                    formControlName="safety_area_two"
                                    [ngClass]="{ 'is-invalid': submitted && f.safety_area_two.errors }">
                                    <option value="" disabled selected hidden>Select Safety Area</option>
                                    <option *ngFor='let option of safety_area; let i = index' value="{{option.title}}">
                                        {{option.title}}</option>
                                    <!-- <option value="3">Area2</option> -->
                                </select>
                                <div *ngIf="submitted && f.safety_area_two.errors" class="invalid-feedback">
                                    <div *ngIf="f.safety_area_two.errors.required">Safety Area is required</div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label>Cultivator</label>
                                <fieldset [disabled]="!f.safety_area_two.value">
                                    <select class="form-control" #myCultivator2
                                        (change)='onOptionsSelected(myCultivator2.value,2)' formControlName="cultivator2"
                                        [ngClass]="{ 'is-invalid': submitted && f.safety_area_two.errors }">

                                        <option value="" disabled selected hidden>Select Cultivator</option>
                                        <option *ngFor='let option of cultivator2; let i = index' [value]="option.title">
                                            {{option.title}}</option>
                                    </select>
                                    <div *ngIf="submitted && f.safety_area_two.errors" class="invalid-feedback">
                                        <div *ngIf="f.safety_area_two.errors.required">Review Date is required</div>
                                    </div>
                                </fieldset>
                            </div>
                            <div class="form-group">
                                <label>Subject</label>
                                <fieldset [disabled]="!f.safety_area_two.value">
                                    <select class="form-control" #mySubject2 (change)='ActivitySelected(mySubject2.value,2)'
                                        formControlName="subject2"
                                        [ngClass]="{ 'is-invalid': submitted && f.safety_area_two.errors }">
                                        <option value="" disabled selected hidden>Select Subject</option>
                                        <option *ngFor='let option of subject2; let i = index' [value]="option.title">
                                            {{option.title}}</option>
                                    </select>
                                </fieldset>
                                <div *ngIf="submitted && f.subject2.errors" class="invalid-feedback">
                                    <div *ngIf="f.subject2.errors.required">Review Date is required</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="bottom-wrap">
                            <div class="form-group">
                                <label>Activities</label>
                                <ng-multiselect-dropdown [disabled]="!f.subject2.value" [placeholder]="'Select Activity'"
                                    [settings]="dropdownSettings2" [data]="dropdownList2"
                                    (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)"
                                    formControlName="activity2"
                                    [ngClass]="{ 'is-invalid': submitted && f.activity2.errors }">
                                </ng-multiselect-dropdown>

                                <div *ngIf="submitted && f.activity2.errors" class="invalid-feedback">
                                    <div *ngIf="f.activity2.errors.required">Review Date is required</div>
                                </div>
                            </div>

                            <div class="form-group">
                                <label>Comments</label>
                                <textarea name="" id="" cols="30" rows="5" placeholder="Enter your comment here"
                                    class="form-control" formControlName="comments2"
                                    [ngClass]="{ 'is-invalid': submitted && f.comments2.errors }"></textarea>
                                <div *ngIf="submitted && f.comments2.errors" class="invalid-feedback">
                                    <div *ngIf="f.comments2.errors.required">Review Date is required</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="bottom-wrap">
                            <div class="form-group">
                                <label>Responsible</label>
                                <input type="text" class="form-control" placeholder="Enter Name"
                                    formControlName="responsible2"
                                    [ngClass]="{ 'is-invalid': submitted && f.responsible2.errors }">
                                <div *ngIf="submitted && f.responsible2.errors" class="invalid-feedback">
                                    <div *ngIf="f.responsible2.errors.required">Review Date is required</div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label>Target Date</label>
                                <input type="date" class="form-control" placeholder="Select Date"
                                    formControlName="target_date2"
                                    [ngClass]="{ 'is-invalid': submitted && f.target_date2.errors }">
                                <div *ngIf="submitted && f.target_date2.errors" class="invalid-feedback">
                                    <div *ngIf="f.target_date2.errors.required">Review Date is required</div>
                                </div>
                                <!-- <i class="fa fa-calendar"></i> -->
                            </div>
                            <div class="form-group">
                                <label>Review Date</label>
                                <input type="date" class="form-control" placeholder="Select Date"
                                    formControlName="review_date2"
                                    [ngClass]="{ 'is-invalid': submitted && f.review_date2.errors }">
                                <div *ngIf="submitted && f.review_date2.errors" class="invalid-feedback">
                                    <div *ngIf="f.review_date2.errors.required">Review Date is required</div>
                                </div>
                                <!-- <i class="fa fa-calendar"></i> -->
                            </div>
                        </div>
                    </div>
                </div>

                <!-- three -->
                <div class="row section-wrap">
                    <div class="col-md-4">
                        <div class="bottom-wrap">
                            <div class="form-group">
                                <label>
                                    <h5>C</h5>Safety Area
                                </label>
                                <select #mySelect3 (change)='Select_cultivator(mySelect3.value,3)' class="form-control"
                                    formControlName="safety_area_three"
                                    [ngClass]="{ 'is-invalid': submitted && f.safety_area_three.errors }">
                                    <option value="" disabled selected hidden>Select Safety Area</option>
                                    <option *ngFor='let option of safety_area; let i = index' value="{{option.title}}">
                                        {{option.title}}</option>
                                </select>
                                <div *ngIf="submitted && f.safety_area_three.errors" class="invalid-feedback">
                                    <div *ngIf="f.safety_area_three.errors.required">Review Date is required</div>
                                </div>

                            </div>
                            <div class="form-group">
                                <label>Cultivator</label>
                                <fieldset [disabled]="!f.safety_area_three.value">
                                    <select class="form-control" #myCultivator3
                                        (change)='onOptionsSelected(myCultivator3.value,3)' formControlName="cultivator3"
                                        [ngClass]="{ 'is-invalid': submitted && f.cultivator3.errors }">
                                        <option value="" disabled selected hidden>Select Cultivator</option>
                                        <option *ngFor='let option of cultivator3; let i = index' [value]="option.title">
                                            {{option.title}}</option>
                                    </select>
                                </fieldset>
                                <div *ngIf="submitted && f.cultivator3.errors" class="invalid-feedback">
                                    <div *ngIf="f.cultivator3.errors.required">Review Date is required</div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label>Subject</label>
                                <fieldset [disabled]="!f.cultivator3.value">
                                    <select class="form-control" #mySubject3 (change)='ActivitySelected(mySubject3.value,3)'
                                        formControlName="subject3"
                                        [ngClass]="{ 'is-invalid': submitted && f.subject3.errors }">

                                        <option value="" disabled selected hidden>Select Subject</option>
                                        <option *ngFor='let option of subject3; let i = index' [value]="option.title">
                                            {{option.title}}</option>
                                    </select>
                                    <div *ngIf="submitted && f.subject3.errors" class="invalid-feedback">
                                        <div *ngIf="f.subject3.errors.required">Review Date is required</div>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="bottom-wrap">
                            <div class="form-group">
                                <label>Activities</label>
                                <ng-multiselect-dropdown [disabled]="!f.subject3.value" [placeholder]="'Select Activity'"
                                    [settings]="dropdownSettings3" [data]="dropdownList3"
                                    (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)"
                                    formControlName="activity3"
                                    [ngClass]="{ 'is-invalid': submitted && f.activity3.errors }">
                                </ng-multiselect-dropdown>
                                <div *ngIf="submitted && f.activity3.errors" class="invalid-feedback">
                                    <div *ngIf="f.activity3.errors.required">Review Date is required</div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label>Comments</label>
                                <textarea name="" id="" cols="30" rows="5" placeholder="Enter your comment here"
                                    class="form-control" formControlName="comments3"
                                    [ngClass]="{ 'is-invalid': submitted && f.comments3.errors }"></textarea>
                                <div *ngIf="submitted && f.comments3.errors" class="invalid-feedback">
                                    <div *ngIf="f.comments3.errors.required">Review Date is required</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="bottom-wrap">
                            <div class="form-group">
                                <label>Responsible</label>
                                <input type="text" class="form-control" formControlName="responsible3"
                                    placeholder="Enter Name"
                                    [ngClass]="{ 'is-invalid': submitted && f.responsible3.errors }">
                                <div *ngIf="submitted && f.responsible3.errors" class="invalid-feedback">
                                    <div *ngIf="f.responsible3.errors.required">Review Date is required</div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label>Target Date</label>
                                <input type="date" class="form-control" placeholder="Select Date"
                                    formControlName="target_date3"
                                    [ngClass]="{ 'is-invalid': submitted && f.target_date3.errors }">
                                <div *ngIf="submitted && f.target_date3.errors" class="invalid-feedback">
                                    <div *ngIf="f.target_date3.errors.required">Review Date is required</div>
                                </div>
                                <!-- <i class="fa fa-calendar"></i> -->
                            </div>
                            <div class="form-group">
                                <label>Review Date</label>
                                <input type="date" class="form-control" placeholder="Select Date"
                                    formControlName="review_date3"
                                    [ngClass]="{ 'is-invalid': submitted && f.review_date3.errors }">
                                <div *ngIf="submitted && f.review_date3.errors" class="invalid-feedback">
                                    <div *ngIf="f.review_date3.errors.required">Review Date is required</div>
                                </div>
                                <!-- <i class="fa fa-calendar"></i> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 offset-md-4">
                    <button type="submit" class="btn btn-primary main-btn mr-2"> Update</button>
                </div>
            </div>
        </form>
    </div>
</div>
import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons, NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { ReportService } from '../reports/report.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment';
import { NotificationService } from '../notification.service';

@Component({
    selector: 'app-compiled-notes',
    templateUrl: './compiled-notes.component.html',
    styleUrls: ['./compiled-notes.component.scss'],
})
export class CompiledNotesComponent implements OnInit {
    closeResult = '';
    user_type:any;
    cycle:any;
    cycle_year:any; 
    updated_at:any;
    start_date:any;
    cycle_id:any;
    end_date:any;
    cycle_no:any;
    meeting_date: NgbDateStruct;
    date: {year: number, month: number};
    senior_compiled_note = {
        id: "",
        dialogue_sheet_type_id: 3,
        data: [
            {
                question:
                    'Interesting points to work on (include reference to diagnosis report)',
                answers: {
                    Row1Col1: '',
                    Row2Col1: '',
                    Row3Col1: '',
                    Row4Col1: '',
                    Row5Col1: '',
                    Row6Col1: '',
                    Row7Col1: '',
                    Row8Col1: '',
                    Row9Col1: '',
                    Row10Col1: '',
                },
            },
            {
                question: 'Possible causes of these points',
                answers: {
                    Row1Col2: '',
                    Row2Col2: '',
                    Row3Col2: '',
                    Row4Col2: '',
                    Row5Col2: '',
                    Row6Col2: '',
                    Row7Col2: '',
                    Row8Col2: '',
                    Row9Col2: '',
                    Row10Col2: '',
                },
            },
            {
                question: 'Things we can do to address these points',
                answers: {
                    Row1Col3: '',
                    Row2Col3: '',
                    Row3Col3: '',
                    Row4Col3: '',
                    Row5Col3: '',
                    Row6Col3: '',
                    Row7Col3: '',
                    Row8Col3: '',
                    Row9Col3: '',
                    Row10Col3: '',
                },
            },
            {
              meeting_date: '',
            },
        ],
    };

    is_update = false;
    is_success_shown = false;

    constructor(
        private reportService: ReportService,
        private spinner: NgxSpinnerService,
        private modalService: NgbModal,
        private calendar: NgbCalendar,
        private notifyService: NotificationService
    ) {}

    ngOnInit(): void {
        this.spinner.show();
        this.meeting_date = this.calendar.getToday();
        this.cycle = JSON.parse(sessionStorage.getItem('current_cycle_id'));
        this.cycle_year =  moment(this.cycle.start_date).format('yyyy');
        this.start_date =  moment(this.cycle.start_date).format('LL');
        this.updated_at =  moment(this.cycle.updated_at).format('YYYY-MM-DD');
        this.end_date =  moment(this.cycle.end_date).format('LL');
        this.cycle_id =  this.cycle.id
        this.cycle_no = this.cycle.cycle_no;
        this.reportService.get_all_dialogue_sheet().subscribe((res: any) => {
            let senior_compiled_note = res.data.find(
                (x) => x.dialogue_sheet_type_id === 3
            );
            if(senior_compiled_note){
                this.is_update = true;
                this.senior_compiled_note.id = senior_compiled_note.id;
                this.senior_compiled_note.data = senior_compiled_note.data;
                let updated_date = typeof senior_compiled_note.data[3] != 'undefined' ? new Date(senior_compiled_note.data[3].meeting_date) : new Date();
                this.meeting_date =  { day: updated_date.getDate(), month: updated_date.getMonth() + 1, year: updated_date.getFullYear()};
            }
            this.spinner.hide();
        });
    }

    open(content) {
        this.modalService
            .open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg' })
            .result.then(
                (result) => {
                    this.closeResult = `Closed with: ${result}`;
                },
                (reason) => {
                    this.closeResult = `Dismissed ${this.getDismissReason(
                        reason
                    )}`;
                }
            );
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

  saveSeniorOfficerCompiledNote() {
    this.spinner.show();
    let date = this.meeting_date.year + "-" + this.meeting_date.month + "-" + this.meeting_date.day;
    this.senior_compiled_note.data[3].meeting_date = date;
    this.reportService
        .create_dialog_sheet(this.senior_compiled_note)
        .subscribe((res: any) => {
            if (res.data) {
                this.notifyService.showSuccess(
                    'Senior Officer Compiled Notes Saved Successfully',
                    'Success'
                );
            }
            this.spinner.hide();
        });
  }

  updateSeniorOfficerCompiledNote() {
      this.spinner.show();
      let date = this.meeting_date.year + "-" + this.meeting_date.month + "-" + this.meeting_date.day;
      this.senior_compiled_note.data[3].meeting_date = date;
      this.reportService
          .update_dialog_sheet(this.senior_compiled_note)
          .subscribe((res: any) => {
              if (res.data) {
                this.notifyService.showSuccess(
                    'Senior Officer Compiled Notes Updated Successfully',
                    'Success'
                );
              }
              this.spinner.hide();
          });
  }
}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { environment } from '../environments/environment';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { RouteReuseStrategy, RouterModule, Routes } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { CodeInputModule } from 'angular-code-input';
import { HeaderComponent } from './header/header.component';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { CheckMailComponent } from './check-mail/check-mail.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { HomeComponent } from './home/home.component';
import { ChooseComponent } from './choose/choose.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { DetailComponent } from './detail/detail.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ReportsComponent } from './reports/reports.component';
import { SearchComponent } from './search/search.component';
import { SdpFormComponent } from './sdp-form/sdp-form.component';
import { CrewSheetComponent } from './crew-sheet/crew-sheet.component';
import { CompiledNotesComponent } from './compiled-notes/compiled-notes.component';
import { OfficStaffComponent } from './offic-staff/offic-staff.component';
import { SeniourOfficerComponent } from './seniour-officer/seniour-officer.component';
import { ViewSdpformComponent } from './view-sdpform/view-sdpform.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { AppSpinnerDirective } from './spinner/app-spinner.directive';
import { AllowDeviceComponent } from './allow-device/allow-device.component';
import { PinComponent } from './pin/pin.component';
import { ChangeCycleComponent } from './change-cycle/change-cycle.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LessonComponent } from './lesson/lesson.component';
import { PreviousRouteComponent } from './previous-route/previous-route.component';
import { ProfileComponent } from './profile/profile.component';
import { ResetPinComponent } from './reset-pin/reset-pin.component';
import { ProfileResetPasswordComponent } from './profile-reset-password/profile-reset-passwordcomponent';
import { CacheRouteService } from './cache.routing.service'

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    LoginComponent,
    ForgotPasswordComponent,
    CheckMailComponent,
    ResetPasswordComponent,
    HomeComponent,
    ChooseComponent,
    DetailComponent,
    SearchComponent,
    ReportsComponent,
    SdpFormComponent,
    CompiledNotesComponent,
    CrewSheetComponent,
    OfficStaffComponent,
    SeniourOfficerComponent,
    ViewSdpformComponent,
    SpinnerComponent,
    AppSpinnerDirective,
    AllowDeviceComponent,
    PinComponent,
    ChangeCycleComponent,
    DashboardComponent,
    LessonComponent,
    PreviousRouteComponent,
    ProfileComponent,
    ResetPinComponent,
    ProfileResetPasswordComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    BrowserAnimationsModule,
    CarouselModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    PdfViewerModule,
    NgxSpinnerModule,
    RouterModule,
    CodeInputModule,
    NgMultiSelectDropDownModule.forRoot(),
    ToastrModule.forRoot({
      preventDuplicates: true
    }),
  ],
  exports: [
    RouterModule
  ],
  providers: [
    { provide: 'apiBase', useValue: environment.apiBase },
    {provide: RouteReuseStrategy,
      useClass: CacheRouteService}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

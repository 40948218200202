import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'safety-delta';
  currentUrl:any = "";
  constructor(private route: ActivatedRoute, private router: Router){
    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        this.currentUrl = ev.url.toString();
      }
    });
  }

  isLogin() {
    if(!this.currentUrl.includes('password/reset') && !this.currentUrl.includes('login') && this.currentUrl != '/generate-pin' && this.currentUrl != '/forgot-password' && this.currentUrl != '/'){
      return true;
    }
    return false;
  }

  ngOnInit(){
   
  }
}


import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

@Component({
    selector: 'app-choose',
    templateUrl: './choose.component.html',
    styleUrls: ['./choose.component.scss'],
})
export class ChooseComponent implements OnInit {
    public user_type: string = '';
    constructor(
        private router: Router,
        private location: Location,
        private activatedRoute: ActivatedRoute
    ) {}

    ngOnInit(): void {
        this.user_type = sessionStorage.getItem('user_type');
    }
    choose_user(argument, role_id) {
        console.log('type user', argument);
        sessionStorage.setItem('user_type', argument);
        sessionStorage.setItem('user_role_id', role_id);
        this.location.back();
    }
}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from '../notification.service';
import { ResetPasswordService } from './profile-reset-password.service';

@Component({
    selector: 'app-profile-reset-password',
    templateUrl: './profile-reset-password.component.html',
    styleUrls: ['./profile-reset-password.component.scss'],
})
export class ProfileResetPasswordComponent implements OnInit {
  
  resetPassword: FormGroup;
  oldPasswordType:boolean = true;
  passwordType:boolean = true;
  confirmPasswordType:boolean = true;

  constructor(
    private notifyService: NotificationService, private fb: FormBuilder, private resetPasswordService: ResetPasswordService){
      this.resetPassword = this.fb.group({
        oldPassword: ['', Validators.required],
        newPassword: ['', Validators.required],
        newPasswordConfirmation: ['', Validators.required],
      });
  }

  ngOnInit(): void {
  }

  submit() {

    if (this.resetPassword.invalid) {
        this.notifyService.showError('Please Fill All Details', 'Error');
        return 'Form Error';
    }
    let data = {
      old_password: this.resetPassword.controls['oldPassword'].value,
      new_password: this.resetPassword.controls['newPassword'].value,
      new_password_confirmation: this.resetPassword.controls['newPasswordConfirmation'].value
    }

    if (
        this.resetPassword.value.newPassword ==
        this.resetPassword.value.newPasswordConfirmation
    ) {
        this.resetPasswordService
            .resetPassword(data)
            .subscribe((event) => {
                this.notifyService.showSuccess(
                    'Password Updated Successfully',
                    'Success'
                );
            },(error) => {
              if(error.status === 422){
                this.notifyService.showError(
                  "Old password is not correct",
                  'Error'
                );
              } else {
                this.notifyService.showError(
                  "Something went wrong. Please try again later",
                  'Error'
                );
              }
            });
    } else {
        this.notifyService.showError(
            "Password doesn't match Password Confirmation",
            'Error'
        );
    }
}
  
}

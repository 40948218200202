import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class DetailService {
    constructor(
        private http: HttpClient,
        @Inject('apiBase') private apiBase: string
    ) {}

    get_lessons(): Observable<any> {
        return this.http.get(this.apiBase + 'lessons');
    }

    get_lesson_detail($id): Observable<any> {
        return this.http.get(this.apiBase + 'lessons/' + $id);
    }

    get_approve(token, email): Observable<any> {
        let mybody = {};
        return this.http.post(
            `${this.apiBase}auth/allow-device?token=+${token}+&email=+${email}`,
            mybody
        );
    }
}

import { Component, OnInit } from '@angular/core';
import { SearchService } from './search.service';
import { AppSpinnerDirective } from '../spinner/app-spinner.directive';
import { SpinnerComponent } from '../spinner/spinner.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { HomeService } from '../home/home.service';
import { DetailService } from '../detail/detail.service';
import { Location } from '@angular/common';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, skipWhile, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NotificationService } from '../notification.service';

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
    tags: any = [];
    locales: any = [];
    quick_access: any = [];
    process_tools: any = [];
    lessons: any = [];
    roles:any = [
        {
            value: "Crew Member",
            id: "1",
            isChecked: true
        },
        {
            value: "Onboard Facilitator",
            id: "2",
            isChecked: true
        },
        {
            value: "Office Staff",
            id: "3",
            isChecked: true
        }
    ];
    public details: any = [];
    public children: any = [];
    public newchild: any = [];
    public child: any = [];
    public thumbnail_path: any = [];
    public title: any = [];
    public files: any = [];
    public content: any = [];
    public newdetail: any = [];
    public desc: any = [];
    public selroles: any = [];
    public stags: any = [];
    public seltags: any = [];
    public subgroup_title: any = [];
    public lesson_det: any = [];
    public loop = 0;
    public keysearch = null;
    public isLocked = false;
    public isfileLocked = false;
    public show = true;
    public filterDefault = true;
    public lesson_det_show = false;
    public pin: string = null;
    selectedLang:any = ""
    pin_error = false;
    fullscreen = false;
    section: any = [];
    lessons_det: any = [];
    lessons_text: any = [];
    lessons_file: any = [];
    related_items: any = [];
    searchTerm:any = "";
    isStartup:boolean = false;

    public user_type = sessionStorage.getItem('user_role_id');
    public cycle = JSON.parse(sessionStorage.getItem('client'));

    searchedKeyword$: BehaviorSubject<any> = new BehaviorSubject(null);
    searchSub$: Subscription;

    constructor(
        private searchserv: SearchService,
        private spinner: NgxSpinnerService,
        private router: Router,
        private notifyService: NotificationService
    ) {}

    ngOnInit(): void {
      this.isStartup = true;
      this.selectedLang = sessionStorage.getItem("selectedLang") ? sessionStorage.getItem("selectedLang") : "en_US";
      this.search();
      this.searchSub$ = this.searchedKeyword$
      .pipe(
        skipWhile(event => !event),
        map(event => event.currentTarget.value),
        debounceTime(700),
        distinctUntilChanged(),
      )
      .subscribe(term => {
         this.searchTerm = term;
         this.search();
      });

      this.getrecent();
    }

    getrecent() {
        this.searchserv.getTags().subscribe((res: any) => {
            if(res.tags){
                res.tags.forEach(element => {
                    let tagObj = {
                        value: element,
                        isChecked: false
                    }
                    this.tags.push(tagObj);
                });
            }
        });

        this.searchserv.getLocale().subscribe((res: any) => {
            this.locales = res.data;
        });
    }

    // get Lesson Details
    navigateToLesson(id) {
        id = btoa(id);
        this.router.navigate(['lesson', id, this.selectedLang]);
    }

    search() {
        this.spinner.show();
        this.fullscreen = false;
        this.filterDefault = false;
        
        let tagValues = [];
        let roleValues = [];

        this.tags.forEach(element => {
            if(element.isChecked){
                tagValues.push(element.value)
            }    
        });

        this.roles.forEach(element => {
            if(element.isChecked){
                roleValues.push(element.id)
            }    
        });
        
        this.searchserv
            .getLessons(
                roleValues,
                tagValues,
                this.selectedLang,
                this.searchTerm,
                this.isStartup
            )
            .subscribe((res: any) => {
                this.spinner.hide();
                if(!res.hasOwnProperty("error")){
                    this.lessons = res.data;
                } else {
                    this.notifyService.showError(
                        res.error.message,
                        'Error'
                    );
                }
                
            },
            (err) => console.log(err));
        this.isStartup = false;
    }

    getTitle(titles){
        let title = "";
        titles.forEach(element => {
            if(element.code.code === this.selectedLang){
                title = element.title;
            }
        });
        return title ? title : titles[0].title;
    }

}

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from '../notification.service';
import { ResetPasswordService } from './reset-password.service';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
    public token: string = '';
    public email: string = '';

    resetForm: FormGroup;
    submitted = false;
    
    passwordType:boolean = true;
    passwordTypeConfirm:boolean = true;
    constructor(
        private router: Router,
        private formBuilder: FormBuilder,
        private notifyService: NotificationService,
        private resetService: ResetPasswordService,
        private activatedRoute: ActivatedRoute
    ) {
        this.token = activatedRoute.snapshot.url[2].path;
        this.email = this.activatedRoute.snapshot.queryParams.email;
    }

    ngOnInit(): void {
        this.resetForm = this.formBuilder.group({
            token: [this.token, Validators.required],
            email: [this.email, Validators.required],
            password: ['', Validators.required],
            password_confirmation: ['', Validators.required],
        });
    }
    get f() {
        return this.resetForm.controls;
    }

    onSubmit() {
        this.submitted = true;

        if (this.resetForm.invalid) {
            this.notifyService.showError('Please Fill All Details', 'Error');
            return 'Form Error';
        }

        if (
            this.resetForm.value.password ==
            this.resetForm.value.password_confirmation
        ) {
            // this.notifyService.showError("Please Fill All Details",'Success');

            this.resetService
                .ResetUserPass(this.resetForm.value)
                .subscribe((event) => {
                    // console.log('post-response:',event);

                    this.notifyService.showSuccess(
                        'Password Updated Successfully',
                        'Success'
                    );

                    setTimeout(() => {
                        this.router.navigate(['/home']);
                    }, 3000);
                });
        } else {
            this.notifyService.showError(
                "Password doesn't match Password Confirmation",
                'Error'
            );
        }
    }
}

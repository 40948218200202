import { Injectable, Inject } from '@angular/core';
import { HttpClient,HttpHeaders  } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

       constructor(
        private http: HttpClient,
        @Inject('apiBase') private apiBase: string
    ) {}


    private httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + sessionStorage.getItem('accessToken'),
      })
    };
    cycle =  JSON.parse(sessionStorage.getItem('current_cycle_id'));
    client_id = JSON.parse(sessionStorage.getItem('client_id'));


    get_dashboard(): Observable<any> {
      return this.http.get(`${this.apiBase}clients/${this.client_id}/dashboard`, this.httpOptions).pipe(catchError(this.handleError));
    }

    get_dashboard_with_Params(cycle_year, cycle_no): Observable<any> {
      return this.http.get(`${this.apiBase}clients/${this.client_id}/dashboard?cycleYear=${cycle_year}&cycleNo=${cycle_no}&export=0`, this.httpOptions).pipe(catchError(this.handleError));
    }

    get_dashboard_excel(cycle_year, cycle_no): Observable<any> {
      const httpOptions = {
        responseType: 'arraybuffer' as 'json',
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + sessionStorage.getItem('accessToken'),
        })
      };
      return this.http.get(`${this.apiBase}clients/${this.client_id}/dashboard?cycleYear=${cycle_year}&cycleNo=${cycle_no}&export=1`, httpOptions).pipe(catchError(this.handleError));
    }

    handleError(error) {
      //window.location.href = "/login";
      let errorMessage = '';
      if (error.error instanceof ErrorEvent) {
        errorMessage = `Error: ${error.error.message}`;
  
      } else {
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      }
      window.alert(errorMessage);
      return throwError(errorMessage);
    }
}

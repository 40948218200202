import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RoutesRecognized } from '@angular/router';
import { filter, pairwise } from 'rxjs/operators';

@Component({
    selector: 'app-previous-route',
    templateUrl: './previous-route.component.html',
    styleUrls: ['./previous-route.component.scss'],
})
export class PreviousRouteComponent implements OnInit {
    routes:any;
    currentRoute:any;
    constructor(
    ) {}

    ngOnInit(): void {
    }

    navigateTo(){
        window.history.back();
    }
}

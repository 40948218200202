import { Component, OnInit } from '@angular/core';
import { NotificationService } from '../notification.service';

@Component({
    selector: 'app-reset-pin',
    templateUrl: './reset-pin.component.html',
    styleUrls: ['./reset-pin.component.scss'],
})
export class ResetPinComponent implements OnInit {
  createdPin:any;
  retypePin:any;
  
  oldPin:any;
  errorMessage:any;

  constructor(
    private notifyService: NotificationService){

  }

  ngOnInit(): void {
    this.oldPin = localStorage.getItem('mypin');
  }

  onCodeChangedPin(code: string) {
    this.createdPin = code;
  }

  onCodeCompletedPin(code: string) {
    this.createdPin = code;
  }

  onCodeChangedRetypePin(code: string) {
    this.retypePin = code;
  }

  onCodeCompletedRetypePin(code: string) {
    this.retypePin = code;
  }

  confirmNewPin(){
    if(this.createdPin === this.retypePin){
      localStorage.removeItem('mypin');
      localStorage.setItem('mypin', this.createdPin);
      this.notifyService.showSuccess(
        'Pin Changed Successfully!',
        'Success'
    );
    } else {
      this.errorMessage = "Pin does not match!"
    }
  }
  
}

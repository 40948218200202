import { Component, OnInit } from '@angular/core';
import { ReportService } from './report.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment';
 // const FileSaver = require('file-saver');

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {

   blob:any;
   user_type:any;
   cycle:any;
   cycle_year:any; 
   updated_at:any;
   start_date:any;
   cycle_id:any;
   end_date:any;
   cycle_no:any;
   dialogue_sheets:any;
   active_id ="";
   sods:any = {
      updated_at: "",
      id:""
   }
   scn:any = {
      updated_at: "",
      id:""
   }
   cds:any = {
      updated_at: "",
      id:""
   }
   osds:any = {
      updated_at: "",
      id:""
   }
   sdpUpdated:any;
   vesselName:any;

   constructor(private report: ReportService, private spinner: NgxSpinnerService,  private reportService: ReportService,) {  }

  ngOnInit(): void {
      this.cycle = JSON.parse(sessionStorage.getItem('current_cycle_id'));
      this.user_type = sessionStorage.getItem('user_type');      
      this.active_id = sessionStorage.getItem('active_id');
      this.vesselName = sessionStorage.getItem('vessel_name');

      if(this.cycle)
      {
         this.cycle_year =  moment(this.cycle.start_date).format('yyyy');
         this.start_date =  moment(this.cycle.start_date).format('LL');
         this.updated_at =  moment(this.cycle.updated_at).format('YYYY-MM-DD');
         this.end_date =  moment(this.cycle.end_date).format('LL');
         this.cycle_id =  this.cycle.id
         this.cycle_no = this.cycle.cycle_no;

      }else{
         this.cycle_year =  '';
         this.start_date =  '';
         this.updated_at =  '';
         this.end_date =  '';
         this.cycle_id =  '';
      }

      if(this.cycle){
         this.reportService.get_all_dialogue_sheet().subscribe((res: any) => {
            let senior_office_dialogue_sheet = res.data.find(
               (x) => x.dialogue_sheet_type_id === 2
            );
            this.sods.updated_at = senior_office_dialogue_sheet ? moment(senior_office_dialogue_sheet.updated_at).format('YYYY-MM-DD') : "";
            this.sods.id = senior_office_dialogue_sheet ? senior_office_dialogue_sheet.id : "";
   
            let senior_compiled_note = res.data.find(
               (x) => x.dialogue_sheet_type_id === 3
            );
            this.scn.updated_at = senior_compiled_note ? moment(senior_compiled_note.updated_at).format('YYYY-MM-DD') : "";
            this.scn.id = senior_compiled_note ? senior_compiled_note.id : "";
   
            let crew_dialogue_sheet = res.data.find(
               (x) => x.dialogue_sheet_type_id === 1
            );
            this.cds.updated_at = crew_dialogue_sheet ? moment(crew_dialogue_sheet.updated_at).format('YYYY-MM-DD') : "";
            this.cds.id = crew_dialogue_sheet ? crew_dialogue_sheet.id : "";
   
            let office_staff_dialogue_sheet = res.data.find(
               (x) => x.dialogue_sheet_type_id === 4
           );
           this.osds.updated_at = office_staff_dialogue_sheet ? moment(office_staff_dialogue_sheet.updated_at).format('YYYY-MM-DD') : "";
           this.osds.id = office_staff_dialogue_sheet ? office_staff_dialogue_sheet.id : "";
   
        });

        this.reportService.getsdp_recent().subscribe((res: any) => {
           this.sdpUpdated = moment(res.updated_at).format('YYYY-MM-DD');
        });
      }
  }

   ViewPdf(){
      this.spinner.show();
      this.report.get_report_pdf().subscribe((res: any) => {
         var newBlob = new Blob([res], {type: "application/pdf"})
         var link = document.createElement('a');
         link.href = window.URL.createObjectURL(newBlob);
         link.download = "CSD_Report_"+ this.vesselName +"_"+ this.cycle_year +"_"+ this.cycle_no +"";;
         link.click();
      });
   }

   setActiveId(id){
      sessionStorage.setItem('active_id', id);
   }


   viewDialogSheetPdf(id, type){
      let name = "";
      if(type === 'sods'){
         name = "Senior_officer_dialogue_sheet_"+ this.vesselName +"_"+ this.cycle_year +"_"+ this.cycle_no +"_"+ this.sods.updated_at +"";
      } else if(type === "scn"){
         name = "Senior_officer_compiled_notes_"+ this.vesselName +"_"+ this.cycle_year +"_"+ this.cycle_no +"_"+ this.sods.updated_at +"";
      } else if(type === "cds"){
         name = "Crew_dialogue_sheet_"+ this.vesselName +"_"+ this.cycle_year +"_"+ this.cycle_no +"_"+ this.sods.updated_at +"";
      } else if(type === "osds"){
         name = "Office_staff_dialogue_sheet_"+ this.vesselName +"_"+ this.cycle_year +"_"+ this.cycle_no +"_"+ this.sods.updated_at +"";
      }

      this.spinner.show();
      this.report.get_dialogue_sheet_pdf(id).subscribe((res: any) => {
         var newBlob = new Blob([res], {type: "application/pdf"})
         var link = document.createElement('a');
         link.href = window.URL.createObjectURL(newBlob);
         link.download = name;
         link.click();
      });
   }

   viewSdpPdf(){
      this.spinner.show();
      this.report.get_sdp_report_file().subscribe((res: any) => {
         var newBlob = new Blob([res], {type: "application/pdf"})
         var link = document.createElement('a');
         link.href = window.URL.createObjectURL(newBlob);
         link.download = "SDP_Form_"+ this.vesselName +"_"+ this.cycle_year +"_"+ this.cycle_no +"";;
         link.click();
      });
   }

}

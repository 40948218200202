<div class="login-wrap">
    <div class="container">
        <div class="row">
            <div class="col-md-6 offset-md-3">
                <div class="pin-logo">
                    <img src="assets/images/logo.png" class="img img-fluid" />
                </div>
                <form *ngIf="!oldPin">
                    <div class="form-head">
                        <h4>Create your PIN</h4>
                        <p>
                            You will use this PIN to access confidential
                            learning materials on the platform. <br />
                            Please nominate a 4-digit PIN.
                        </p>
                    </div>
                    <div class="pin-div">
                        <div class="form-group">
                          <h6>PIN</h6>
                            <code-input
                                [isCodeHidden]="true"
                                [codeLength]="4"
                                (codeChanged)="onCodeChangedPin($event)"
                                (codeCompleted)="onCodeCompletedPin($event)"
                            >
                            </code-input>
                        </div>
                        <div class="form-group">
                            <h6>Retype PIN</h6>
                            <code-input
                                [isCodeHidden]="true"
                                [codeLength]="4"
                                (codeChanged)="onCodeChangedRetypePin($event)"
                                (codeCompleted)="onCodeCompletedRetypePin($event)"
                            >
                            </code-input>
                        </div>
                    </div>
                    <p class="error">{{errorMessage}}</p>
                    <button (click)="confirmNewPin()" class="btn btn-primary main-btn">Confirm</button>
                </form>
                <form *ngIf="oldPin">
                  <div class="form-head">
                      <h4>Please enter PIN</h4>
                      <p>
                          You will use this PIN to access confidential
                          learning materials on the platform. <br />
                          Please nominate a 4-digit PIN.
                      </p>
                  </div>
                  <div class="pin-div">
                      <div class="form-group">
                          <code-input
                              [isCodeHidden]="true"
                              [codeLength]="4"
                              (codeChanged)="onCodeChangedUserPin($event)"
                              (codeCompleted)="onCodeCompletedUserPin($event)"
                          >
                          </code-input>
                      </div>
                  </div>
                  <p class="error">{{errorMessage}}</p>
                  <button (click)="confirmOldPin()" class="btn btn-primary main-btn">Confirm</button>
                  <div class="row mt-3">
                      <div class="col-md-6"></div>
                      <div class="col-md-6">
                          <a (click)="resetPin()"
                              >Reset Pin</a
                          >
                      </div>
                  </div>
                </form>
            </div>
        </div>
    </div>
</div>

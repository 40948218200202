<div class="choose-wrap">
    <div class="header">
        <div class="container">
           
        </div>
    </div>
    <div class="main-section">
        <div class="container">
            <h4>Which account do you want to use?</h4>
            <div class="option-box">
                <ul>
                    <li (click)="choose_user('Office_Staff',3)">
                        <div class="img-wrap">
                            <img src="assets/images/staff.svg" class="img img-fluid">
                        </div>
                        <p *ngIf="user_type =='Office_Staff'" style="color:green"> Office Staff (Active)</p>
                         <p *ngIf="user_type !='Office_Staff'"> Office Staff</p>
                    </li>
                    <li (click)="choose_user('Crew',1)">
                        <div class="img-wrap">
                            <img src="assets/images/crew.svg" class="img img-fluid">
                        </div>
                         <p *ngIf="user_type =='Crew'" style="color:green"> Crew (Active)</p>
                        <p *ngIf="user_type !='Crew'">Crew</p>
                    </li>
                    <li (click)="choose_user('Onboard_Facilitator',2)">
                        <div class="img-wrap" >
                            <img src="assets/images/onboard.svg" class="img img-fluid">
                        </div>
                         <p *ngIf="user_type =='Onboard_Facilitator'" style="color:green"> Onboard Facilitator (Active)</p>
                        <p *ngIf="user_type !='Onboard_Facilitator'">Onboard Facilitator</p>
                    </li>
                </ul>
            </div>
        </div>
    </div>    
</div>
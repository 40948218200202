import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ChooseComponent } from './choose/choose.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { CheckMailComponent } from './check-mail/check-mail.component';
import { HomeComponent } from './home/home.component';
import { RouteGuard } from './guards/route.guard';
import { DetailComponent } from './detail/detail.component';
import { ReportsComponent } from './reports/reports.component';
import { SdpFormComponent } from './sdp-form/sdp-form.component';
import { CrewSheetComponent } from './crew-sheet/crew-sheet.component';
import { CompiledNotesComponent } from './compiled-notes/compiled-notes.component';
import { OfficStaffComponent } from './offic-staff/offic-staff.component';
import { SeniourOfficerComponent } from './seniour-officer/seniour-officer.component';
import { ViewSdpformComponent } from './view-sdpform/view-sdpform.component';
import { SearchComponent } from './search/search.component';
import { AllowDeviceComponent } from './allow-device/allow-device.component';
import { PinComponent } from './pin/pin.component';
import { ChangeCycleComponent } from './change-cycle/change-cycle.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LessonComponent } from './lesson/lesson.component';
import { ProfileComponent } from './profile/profile.component';
import { ResetPinComponent } from './reset-pin/reset-pin.component';
import { ProfileResetPasswordComponent } from './profile-reset-password/profile-reset-passwordcomponent';

const routes: Routes = [
    { path: '', pathMatch: 'full', redirectTo: 'login' },
    {
        path: 'login',
        component: LoginComponent,
        data: { title: 'Safety Delta Login| Login' },
    },
    {
        path: 'reset-password',
        component: ResetPasswordComponent,
        data: { title: 'Safety Delta | Login' },
    },
    {
        path: 'choose',
        component: ChooseComponent,
        data: { title: 'Safety Delta | Login' },
    },
    {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
        data: { title: 'Safety Delta | Login' },
    },
    {
        path: 'check-mail',
        component: CheckMailComponent,
        data: { title: 'Safety Delta | Login' },
    },
    {
        path: 'password/reset/:token?email',
        component: ResetPasswordComponent,
        data: { title: 'Safety Delta | Login' },
    },
    {
        path: 'home',
        component: HomeComponent,
        canActivate: [RouteGuard],
        data: { title: 'Safety Delta | Homepage' },
    },
        {
        path: 'report',
        component: ReportsComponent,
        canActivate: [RouteGuard],
        data: { title: 'Reports | Report' },
    },
        {
        path: 'office-staff',
        component: OfficStaffComponent,
        canActivate: [RouteGuard],
        data: { title: 'Reports | Report' },
    },
        {
        path: 'seniour-officer',
        component: SeniourOfficerComponent,
        canActivate: [RouteGuard],
        data: { title: 'Reports | Report' },
    },
    {
        path: 'lesson',
        component: DetailComponent,
        canActivate: [RouteGuard],
        data: { title: 'Lessons | Detail' },
    },
    {
        path: 'sdp-form',
        component: SdpFormComponent,
        canActivate: [RouteGuard],
        data: { title: 'SDP-Form | SDP-Form' },
    },
    {
        path: 'view-sdp-form',
        component: ViewSdpformComponent,
        canActivate: [RouteGuard],
        data: { title: 'View SDP-Form | View SDP-Form' },
    },
    {
        path: 'compiled-notes',
        component: CompiledNotesComponent,
        canActivate: [RouteGuard],
        data: { title: 'Compiled-Notes | Compiled-Notes' },
    },
    {
        path: 'crew-sheet',
        component: CrewSheetComponent,
        canActivate: [RouteGuard],
        data: { title: 'SDP-Form | SDP-Form' },
    },
    {
        path: 'change-cycle',
        component: ChangeCycleComponent,
        canActivate: [RouteGuard],
        data: { title: 'Change Cycle' },
    },
    {
        path: 'search',
        component: SearchComponent,
        canActivate: [RouteGuard],
        data: { title: 'Search | Search' },
    },
    {
        path: 'allow-device',
        component: AllowDeviceComponent,
        data: { title: 'Allow Device | Allow' },
    },
    {
        path: 'generate-pin',
        component: PinComponent,
        data: { title: 'Pin' },
    },
    {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [RouteGuard],
        data: { title: 'Dashboard' },
    },
    {
        path: 'profile',
        component: ProfileComponent,
        canActivate: [RouteGuard],
        data: { title: 'Profile' },
    },
    {
        path: 'reset-pin',
        component: ResetPinComponent,
        canActivate: [RouteGuard],
        data: { title: 'Reset Pin' },
    },
    {
        path: 'lesson/:id/:lang',
        component: LessonComponent,
        canActivate: [RouteGuard],
        data: { title: 'Lesson' },
    },
    {
        path: 'profile/reset-password',
        component: ProfileResetPasswordComponent,
        canActivate: [RouteGuard],
        data: { title: 'Reset Password' },
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes,  { useHash: false })],
    exports: [RouterModule],
})
export class AppRoutingModule {}

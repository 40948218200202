
<div class="home-wrap">
    <div class="container">
        <div *ngIf="cycle" class="report-wrap">
			<div class="row">
				<div class="col-md-8 offset-md-2">
					<h5 class="text-center">Cycle Year: {{ cycle_year}} Cycle: {{cycle_no}} ({{start_date}} - {{end_date}})</h5>
				</div>
			</div>
          	<h4>Reports</h4>
                <div class="row">
                    <div class="col-md-3">
                        <ul ngbNav [(activeId)]="active_id" #nav="ngbNav"  class="nav-pills" orientation="vertical">
                            <li ngbNavItem="top">
                              <a ngbNavLink (click)="setActiveId('top')">CSD Report</a>
                              <ng-template ngbNavContent>
                                <div class="inner-tab">
									<div class="rep-box">
										<div class="row">
											<div class="col-md-3 col-xs-6">
												<img src="assets/images/csd.png" class="img img-fluid">
											</div>
											<div class="col-md-6 col-xs-6">
												<h6>Last Modified</h6> 
												<p>{{updated_at}}</p>
											</div>
											<div class="col-md-3 col-sm-12 text-right">
												<!-- <button class="btn fill-width btn-primary main-btn mb-3" (click)="ViewPdf()">View</button> -->
												<button class="btn fill-width btn-primary main-btn" (click)="ViewPdf()">View PDF</button>
											</div>
										</div>
									</div>
                                </div>
                              </ng-template>
                            </li>
                            <li ngbNavItem="middle">
                              <a ngbNavLink (click)="setActiveId('middle')">Dialogue Sheet</a>
                              <ng-template ngbNavContent>
                                <div class="inner-tab">
                                  <div class="table-responsive">
                                      <table class="table">
                                        <thead>
                                            <tr>
                                              <th>Dialogue Sheet</th>
                                              <th>Last Modified</th>
                                              <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td>Senior Officer Dialogue Sheet</td>
                                            <td>{{sods.updated_at}}</td>
                                            <td>
												<div ngbDropdown>
													<img src="assets/images/kebab-icon.svg" ngbDropdownToggle class="img img-fluid">
													<div class="dropdown-menu" ngbDropdownMenu>
														<button ngbDropdownItem [routerLink]="['/seniour-officer']">Fillout</button>
														<button (click)="viewDialogSheetPdf(sods.id, 'sods')" ngbDropdownItem>View Pdf</button>
													</div>
												</div>
											</td>
                                          </tr>
										  <tr>
                                            <td>Senior Officer Compiled Notes</td>
                                            <td>{{scn.updated_at}}</td>
                                            <td>
												<div ngbDropdown>
													<img src="assets/images/kebab-icon.svg" ngbDropdownToggle class="img img-fluid">
													<div class="dropdown-menu" ngbDropdownMenu>
														<button ngbDropdownItem [routerLink]="['/compiled-notes']">Fillout</button>
														<button (click)="viewDialogSheetPdf(scn.id, 'scn')" ngbDropdownItem>View Pdf</button>
													</div>
												</div>
											</td>
                                          </tr>
                                          <tr>
                                            <td>Crew Dialogue Sheet</td>
                                            <td>{{cds.updated_at}}</td>
                                            <td>
												<div ngbDropdown>
													<img src="assets/images/kebab-icon.svg" ngbDropdownToggle class="img img-fluid">
													<div class="dropdown-menu" ngbDropdownMenu>
														<button ngbDropdownItem [routerLink]="['/crew-sheet']">Fillout</button>
														<button (click)="viewDialogSheetPdf(cds.id, 'cds')" ngbDropdownItem>View Pdf</button>
													</div>
												</div>
											</td>
                                          </tr>
                                          <tr>
                                            <td>Office Staff Dialogue Sheet</td>
                                            <td>{{osds.updated_at}}</td>
                                            <td>
												<div ngbDropdown>
													<img src="assets/images/kebab-icon.svg" ngbDropdownToggle class="img img-fluid">
													<div class="dropdown-menu" ngbDropdownMenu>
														<button ngbDropdownItem [routerLink]="['/office-staff']">Fillout</button>
														<button (click)="viewDialogSheetPdf(osds.id, 'osds')" ngbDropdownItem>View Pdf</button>
													</div>
												</div>
											</td>
                                          </tr>
                                        </tbody>
                                      </table>
                                  </div>
                                </div>
                              </ng-template>
                            </li>
                            <li ngbNavItem="bottom">
                              <a ngbNavLink (click)="setActiveId('bottom')">SDP Form</a>
                              <ng-template ngbNavContent>
                                <div class="inner-tab">
									<div class="rep-box">
										<div class="row">
											<div class="col-md-3 col-xs-6">
												<img src="assets/images/sdp.png" class="img img-fluid">
											</div>
											<div class="col-md-6 col-xs-6">
												<h6>Last Modified</h6> 
												<p>{{sdpUpdated}}</p>
											</div>
											<div class="col-md-3 col-sm-12 text-right">
												<button class="btn fill-width btn-primary main-btn mb-3" [routerLink]="['/sdp-form']">Fill Out</button>
												<button class="btn fill-width btn-primary main-btn" (click)="viewSdpPdf()">View Completed SDP</button>
											</div>
										</div>
									</div>
                                </div>
                              </ng-template>
                            </li>
                          </ul>
                    </div>
                    <div class="col-md-9">
                        <div [ngbNavOutlet]="nav"></div>
                    </div>
                </div>
           
        </div>
		<div *ngIf="!cycle" class="report-wrap">
			<div class="row">
				<div class="col-sm-6 offset-sm-3">
					<h1>No Cycle Available</h1>
				</div>
			</div>
		</div>
    </div>
</div>

import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { HomeService } from './home.service';
import {SpinnerComponent} from '../spinner/spinner.component';
import {AppSpinnerDirective} from '../spinner/app-spinner.directive';
import {BrowserModule} from '@angular/platform-browser';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public cycle = JSON.parse(sessionStorage.getItem('client'));
  public quick_access:any=[];
  public process_tools:any=[];
  public homeData:any = JSON.parse(localStorage.getItem('homeCollection'));
  selectedLang:any = "en_US"

  constructor(private homeserv: HomeService,private spinner: NgxSpinnerService, private router: Router) { }

  ngOnInit(): void {
    this.selectedLang = sessionStorage.getItem("selectedLang") ? sessionStorage.getItem("selectedLang") : "en_US";

    if(!this.homeData){
      this.getrecent();
    } else {
      this.quick_access = this.homeData.data.subscription.package_items[1];
      this.process_tools = this.homeData.data.subscription.package_items[0];
      this.spinner.hide();
    }
              
  }

  getrecent(){
    this.spinner.show();
    this.homeserv.gethomeData(this.cycle.id).subscribe((res: any) => {
      localStorage.setItem('homeCollection', JSON.stringify(res));
      this.quick_access = res.data.subscription.package_items[1];
      this.process_tools = res.data.subscription.package_items[0];
      this.spinner.hide();
    });
  }

  navigateToLesson(id) {
    id = btoa(id);
    this.router.navigate(['lesson', id, this.selectedLang]);
  }

  getTitle(titles){
    let selLang = sessionStorage.getItem("selectedLang") ? sessionStorage.getItem("selectedLang") : "en_US"; //for checking if language was updated
    let title = "";
    titles.forEach(element => {
        if(element.code.code === this.selectedLang){
            title = element.title;
        }
    });
    return title ? title : titles[0].title;
}

}

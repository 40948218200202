import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoginService } from '../login/login.service';
import { Router } from '@angular/router';
import { NotificationService } from '../notification.service';


@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
    public forgotForm: FormGroup;
    constructor(
        private fb: FormBuilder,
        private router: Router,
        private notifyService : NotificationService,
        private service: LoginService
    ) {}

    ngOnInit(): void {
        this.initForgotForm();
    }

    initForgotForm() {
        this.forgotForm = this.fb.group({
            email: [
                '',
                [
                    Validators.required,
                    Validators.pattern(
                        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    ),
                ],
            ],
        });
    }

    forgotSubmit() {
        for (const c of Object.keys(this.forgotForm.controls)) {
            this.forgotForm.controls[c].markAsTouched();
        }
        console.log(this.forgotForm.get('email').value);
        if (this.forgotForm.valid) {
            this.service
                .forgotPassword(this.forgotForm.value)
                .subscribe((res) => {
                    console.log(res);
                    if (res.data) {
                        if (res.data.result == true) {
                     this.notifyService.showSuccess("Mail Sent Successfully. Please check Inbox.",'Success');
                            //this.router.navigate(['/check-mail']);
                            this.router.navigate(['/login']);
                        }
                    } else {
                      this.notifyService.showError("User Doesn't Exists.",'Error');

                    }
                },error => {
                console.error(error,'error');
                 this.notifyService.showError("User Doesn't Exists.",'Error'); 
             } );
        }
    }
}

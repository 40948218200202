import { Injectable, Inject } from '@angular/core';
import { HttpClient,HttpHeaders  } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ChangeCycleService {

       constructor(
        private http: HttpClient,
        @Inject('apiBase') private apiBase: string
    ) {}


    private httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + sessionStorage.getItem('accessToken'),
      })
    };
    cycle =  JSON.parse(sessionStorage.getItem('current_cycle_id'));


    get_cycles(): Observable<any> {
      return this.http.get(`${this.apiBase}cycles/${this.cycle.id}`, this.httpOptions).pipe(catchError(this.handleError));
    }

    update_cycles(cycle): Observable<any> {
      return this.http.put(`${this.apiBase}cycles/${this.cycle.id}/update`, cycle, this.httpOptions).pipe(catchError(this.handleError));
    }

    handleError(error) {
      //window.location.href = "/login";
      let errorMessage = '';
      if (error.error instanceof ErrorEvent) {
        errorMessage = `Error: ${error.error.message}`;
  
      } else {
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      }
      window.alert(errorMessage);
      return throwError(errorMessage);
    }
}

<div class="spinner-container">
  <div class="spinner-content">
    <div class="spinner-icon-container">
      <div class="la-line-spin-fade-rotating la-2x spinner-icon">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div *ngIf="message" class="spinner-message">{{message}}</div>
  </div>
</div>

<div class="home-wrap">
    <div class="container">
        <div class="row">
            <div class="col-6">
                <ul class="slide-tool">
                    <li><i class="fa fa-angle-left"></i></li>
                    <li><i class="fa fa-angle-right"></i></li>
                </ul>
            </div>
            <div class="col-6">
                <div ngbDropdown class="d-inline-block lang-drop">
                    <button class="btn" id="dropdownBasic1" ngbDropdownToggle>English (US)</button>
                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                        <button ngbDropdownItem>Chineese</button>
                        <button ngbDropdownItem>French</button>
                        <button ngbDropdownItem>German</button>
                        <button ngbDropdownItem>Greek</button>
                        <button ngbDropdownItem>Italian</button>
                    </div>
                </div>
            </div>
        </div>

        <h4>{{lessons_det.title}}</h4>
        <div class="row">


            <div class="col-md-8">
                <div class="detail-left" *ngFor="let sec of section; index as i " [attr.data-index]="i">
                    
                    <div class="info" id="myText" *ngFor="let lesson_text of lessons_text; index as j" >
                      
                      <div class="info" *ngIf=" sec.id ==  lesson_text.parent_id" [innerHTML]="lesson_text.element_type[0].data.content"></div>
                    </div>   


                    <div class="recent-wrap" id="myText" *ngFor="let related_item  of related_items ; index as j" >
                      
                      <div *ngIf=" sec.id ==  related_item.parent_id">
                        <h6>Related Subjects</h6>
                          <div *ngFor="let rel_item of related_item.element_type[0].data.items" class="recent-box">
                              
                              <img src="{{rel_item.thumbnail_path ||'https://green-jakobsen.s3.ap-southeast-1.amazonaws.com/sitemap/19/images/VO6WzyDhFrnGv3up3fxLRhrTnGEDAYTn3GoV4ok2.png'}}" > 
                              <a href="#" class="kt-widget4__username">
                                {{rel_item.title}}
                                </a>
                           
                          </div>
                      </div>
                    </div>




                <!--     <div class="info" id="myText" *ngFor="let lesson_file of lessons_file; index as j" >
                      
                      <div *ngIf=" sec.id ==  lesson_file.parent_id" >
                          <img src="{{lesson_file.element_type[0].data.itemfile.thumbnail_path}}"> <span> {{lesson_file.element_type[0].data.itemfile.filename}} </span>
                      </div>
                    </div> -->



                    <div class="activity-wrap" *ngFor="let lesson_file of lessons_file; index as j">
                        
                        <div class="activity-box" id="activity-box" *ngIf=" sec.id ==  lesson_file.parent_id">
                            <div class="row">
                                <div class="col-md-2 col-4">
                                    <div class="act-img">
                                        <img src="{{lesson_file.element_type[0].data.itemfile.thumbnail_path || 'https://green-jakobsen.s3.ap-southeast-1.amazonaws.com/sitemap/19/images/VO6WzyDhFrnGv3up3fxLRhrTnGEDAYTn3GoV4ok2.png' }}" class="img img-fluid">
                                    </div>
                                </div>
                                <div class="col-md-10 col-8">
                                    <div class="act-detail" >
                                        <h2>{{lesson_file.element_type[0].data.itemfile.filename}}</h2>
                                        <p>{{lesson_file.element_type[0].data.itemfile.description}}</p>
                                   
                                      <!-- <span  class="show-pdf" data-toggle="modal"  data-target="#ImageViewModal" id="{{lesson_file.element_type[0].data.itemfile.file_path}}" > </span> --> <a href="{{lesson_file.element_type[0].data.itemfile.file_path}}" target="_blank">  <img src="assets/images/link.svg" class="img img-fluid link-img"> </a>  
                                    </div>
                                </div>
                            </div>
                        </div>
                     

                    </div>

 
                </div>
            </div>

            <!-- Modal -->
<!--         <div class="modal fade" id="ImageViewModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" >
  <div class="modal-dialog" role="document">
    <div class="image-modal-content">
 
      <div class="modal-image-body">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>

         <div class="image_model_levy">
           <p>jhj</p>
         </div>
      </div>

    </div>
  </div>
</div> -->
                    <!-- Model End -->

            <div class="col-md-4">
                <div class="detail-right">
                    <h6>Lessons</h6>
                    <div class="row">
                        <div class="col-md-6 col-6" *ngFor="let lesson of lessons" (click)="onClickMe(lesson.id)">
                            <div class="res-box">
                                <div class="res-img">
                                    
                                    <img src="{{lesson.thumbnail}}" class="img img-fluid">
                                </div>
                                <p >{{lesson.title}}</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
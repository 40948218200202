import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from '../notification.service';
import { DetailService } from '../detail/detail.service';

@Component({
  selector: 'app-allow-device',
  templateUrl: './allow-device.component.html',
  styleUrls: ['./allow-device.component.scss']
})
export class AllowDeviceComponent implements OnInit {
  public token:string='';
     public email:string='';
     public response:string='';

  constructor( 
    private router: Router,
    private formBuilder: FormBuilder,
    private notifyService : NotificationService,
    private activatedRoute: ActivatedRoute,
    private detail: DetailService
    ) {
    
     this.activatedRoute.queryParams.subscribe(data=>{
      //console.log('ff',data);
      this.email = data.email;
      this.token = data.token;
     });
    // this.token =  this.activatedRoute.snapshot.url[2].path;
    // this.email =  this.activatedRoute.snapshot.queryParams.email;

   }

  ngOnInit(): void {


this.detail.get_approve(this.token,this.email).subscribe((res: any=[])=>{


        if(res.data.result == false)
        {
           this.response = "Device not Allowed";
        } else {
           this.response = "Device Allowed Successfully";
        }
  });
        } 

}


<div class="home-wrap">
    <div class="container">
        <div class="sub-head-wrap">
            <div class="row">
                <div class="col-6">
                    <ul class="slide-tool">
                        <li><a [routerLink]="['/report']"> <i class="fa fa-angle-left"></i> </a></li>
                    </ul>
                </div>
            </div>
            <h4>Development Plan</h4>
            <ul class="info">
                <li>Cycle Year: {{cycle_year}}</li>
                <li>Cycle {{cycle_id }}: {{start_date}} - {{end_date}}</li>
            </ul>
            <ul class="info">
                <li>Vessel XX</li>
                <li>Period Covered: {{start_date}} - {{end_date}}</li>
            </ul>
        </div>
   
        <div class="output-wrap">
            <div class="output-head">
                <div class="row">
                    <div class="col-md-4">
                        <h6>Identify Subject</h6>
                    </div>
                    <div class="col-md-4">
                        <h6>Assemble Learning Package</h6>
                    </div>
                    <div class="col-md-4">
                        <h6>Schedule Learning Package</h6>
                    </div>
                </div>
            </div>
            <div class="row section-wrap">

                <div class="col-md-4">
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <label><h5>A</h5>Safety Area</label>
                            <select class="form-control" >
                                <!-- <option value="" disabled selected hidden>Select safety area</option> -->
                                <!-- <option *ngFor='let option of safety_area; let i = index' value="{{option.title}}">{{option.title}}</option> -->
                                <option selected value="{{sdp_data.SafetyArea1}}">{{sdp_data.SafetyArea1}}</option>
                            </select>
                   
                        </div>
                        <div class="form-group">
                            <label>Cultivator</label>
                            <select class="form-control" >
                            	<option selected value="{{sdp_data.Cultivator1}}">{{sdp_data.Cultivator1}}</option>
                                                 </select>
                           
                        </div>
                        <div class="form-group">
                            <label>Subject</label>
                            <select class="form-control" >
                              <option selected value="{{sdp_data.Subject1}}">{{sdp_data.Subject1}}</option>
                            </select>
                           
                        </div>
                    </div>
                </div>
                
                <div class="col-md-4">
                    <div class="bottom-wrap">
                       
                    <div class="form-group " >
                            <label>Activities</label>
                          <select multiple="">
                          	<option selected="">{{sdp_data.Activity1Row1}}</option>
                          	<option selected="">{{sdp_data.Activity1Row2}}</option>
                          	<option selected="">{{sdp_data.Activity1Row3}}</option>
                          	<option selected="">{{sdp_data.Activity1Row4}}</option>
                          	<option selected="">{{sdp_data.Activity1Row5}}</option>
                          </select>
                         
                        </div>
                    
   
                        <div class="form-group">
                            <label>Comments</label>
                            <textarea name="" id="" cols="30" rows="5"  placeholder="Enter your comment here" class="form-control" value="{{sdp_data.Comments1}}" ></textarea>
                          
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <label>Responsible</label>
                            <input type="text" class="form-control" value="{{sdp_data.Responsible1}}" placeholder="Enter Name">
                            
                        </div>
                        <div class="form-group">
                            <label>Target Date</label>
                            <input type="text" class="form-control" value="{{sdp_data.TargetDate1}}"  placeholder="Select Date">
                            
                        </div>
                        <div class="form-group">
                            <label>Review Date</label>
                            <input type="text" class="form-control" value="{{sdp_data.ReviewDate1}}" placeholder="Select Date" >
                          
                        </div>
                       

                    </div>
                </div>
            </div>

            <!-- two -->
            <div class="row section-wrap">
                <div class="col-md-4">
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <label><h5>B</h5>Safety Area</label>
                              <select  class="form-control" >
                                 <option selected value="{{sdp_data_two.SafetyArea2}}">{{sdp_data_two.SafetyArea2}}</option>
                            </select>
                      
                        </div>
                        <div class="form-group">
                            <label>Cultivator</label>
                             <select class="form-control" >
                                <option selected value="{{sdp_data_two.Cultivator2}}">{{sdp_data_two.Cultivator2}}</option>
                                
                            </select>
                           
                        </div>
                        <div class="form-group">
                            <label>Subject</label>
                             <select class="form-control" >
                              <option selected value="{{sdp_data_two.Subject2}}">{{sdp_data_two.Subject2}}</option>
                            </select>
                          
                        </div>
                    </div>
                </div>
                
                <div class="col-md-4">
                    <div class="bottom-wrap">
                        <div class="form-group">
                            
                            <label>Activities</label>
                          <select multiple="">
                          	<option selected="">{{sdp_data_two.Activity2Row1}}</option>
                          	<option selected="">{{sdp_data_two.Activity2Row2}}</option>
                          	<option selected="">{{sdp_data_two.Activity2Row3}}</option>
                          	<option selected="">{{sdp_data_two.Activity2Row4}}</option>
                          	<option selected="">{{sdp_data_two.Activity2Row5}}</option>
                          </select>

                        </div>
                 
                        <div class="form-group">
                            <label>Comments</label>
                            <textarea name="" id="" value="{{sdp_data_two.Comments2}}" cols="30" rows="5" placeholder="Enter your comment here" class="form-control" ></textarea>
                           
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <label>Responsible</label>
                            <input type="text" class="form-control" value="{{sdp_data_two.Responsible2}}" placeholder="Enter Name" >
                            
                        </div>
                        <div class="form-group">
                            <label>Target Date</label>
                            <input type="date" value="{{sdp_data_two.TargetDate2}}" class="form-control" placeholder="Select Date" >
                           
                            <!-- <i class="fa fa-calendar"></i> -->
                        </div>
                        <div class="form-group">
                            <label>Review Date</label>
                            <input type="date" class="form-control" value="{{sdp_data_two.ReviewDate2}}" placeholder="Select Date" >
                            
                            <!-- <i class="fa fa-calendar"></i> -->
                        </div>
                    </div>
                </div>
            </div>

            <!-- three -->
            <div class="row section-wrap">
                <div class="col-md-4">
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <label><h5>C</h5>Safety Area</label>
                              <select  class="form-control"  >
                                <option selected value="{{sdp_data_three.SafetyArea3}}">{{sdp_data_three.SafetyArea3}}</option>                           
                            </select>
                            

                        </div>
                        <div class="form-group">
                            <label>Cultivator</label>
                            <select class="form-control" >
                               <option selected value="{{sdp_data_three.Cultivator3}}">{{sdp_data_three.Cultivator3}}</option>
                            </select>
                             
                        </div>
                        <div class="form-group">
                            <label>Subject</label>
                            <select class="form-control" >
                            	   <option selected value="{{sdp_data_three.Subject3}}">{{sdp_data_three.Subject3}}</option>
                                                           </select>
                              
                        </div>
                    </div>
                </div>
                
                <div class="col-md-4">
                    <div class="bottom-wrap">
                        <div class="form-group">
                            
                            <label>Activities</label>
                                 <select multiple="">
                          	<option selected="">{{sdp_data_three.Activity2Row1}}</option>
                          	<option selected="">{{sdp_data_three.Activity2Row2}}</option>
                          	<option selected="">{{sdp_data_three.Activity2Row3}}</option>
                          	<option selected="">{{sdp_data_three.Activity2Row4}}</option>
                          	<option selected="">{{sdp_data_three.Activity2Row5}}</option>
                          </select>
                            
                        
                        </div>
                        
                        <div class="form-group">
                            <label>Comments</label>
                            <textarea name="" id="" value="{{sdp_data_three.Comments3}}" cols="30" rows="5" placeholder="Enter your comment here" class="form-control"></textarea>
                             
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <label>Responsible</label>
                            <input type="text" value="{{sdp_data_three.Responsible3}}" class="form-control"  placeholder="Enter Name" >
                            
                        </div>
                        <div class="form-group">
                            <label>Target Date</label>
                            <input type="date" value="{{sdp_data_three.TargetDate3}}" class="form-control" placeholder="Select Date" >
                          
                        </div>
                        <div class="form-group">
                            <label>Review Date</label>
                            <input type="date" value="{{sdp_data_three.ReviewDate3}}" class="form-control" placeholder="Select Date" >
                              
                        </div>
                    </div>
                </div>
            </div>
         <button type="submit" disabled="" class="btn btn-primary main-btn mr-2"> Update</button>
        </div>
 
    </div>
</div>

<div class="home-wrap">
    <div class="container">
        <div class="sub-head-wrap">
            <div class="row">
                <div class="col-6">
                    <ul class="slide-tool">
                        <li [routerLink]="['/report']"><i class="fa fa-angle-left"></i></li>
                    </ul>
                </div>
            </div>
            <h4>Crew Dialogue Sheet</h4>
            <ul class="info">
                <li>Cycle Year: {{cycle_year}}</li>
                <li>Cycle {{cycle_no}}: {{start_date}} - {{end_date}}</li>
            </ul>
            <div class="other-info">
                <div class="row">
                    <div class="col-md-6">
                        <div class="inner-info">
                            <h5 class="header-description">Target User</h5>
                            <p>All crew members</p>
                        </div>
                        <div class="inner-info">
                            <h5 class="header-description">Aim</h5>
                            <p>The aim of the Crew dialogue sheet is for the crew to identify interesting points in the
                                diagnosis report. These points will be discussed during the crew dialogue meeting and
                                serve as a basis for development actions.</p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="inner-info">
                            <h5 class="header-description">Instructions</h5>
                            <ol>
                                <li>Read the diagnosis report.</li>
                                <li>To be prepared for the dialogue, complete the table below individually or by
                                    group (two to three ratings and one Junior Officer).</li>
                            </ol>
                        </div>
                        <div class="inner-info">
                            <h5 class="header-description">Remember…</h5>
                            <ul>
                                <li>to focus on both positive and challenging trends</li>
                                <li>that there are no right or wrong answers</li>
                                <li>that the purpose of the dialogue is for the crew to share opinions and ideas</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <button class="btn btn-primary main-btn sec-btn" (click)="open(content)">View Sample</button>
        </div>

        <div class="output-wrap">
            <div class="output-head">
                <div class="row">
                    <div class="col-md-4">
                        <h6>What are the most interesting points in the report?</h6>
                    </div>
                    <div class="col-md-4">
                        <h6>Why are these points interesting?</h6>
                    </div>
                    <div class="col-md-4">
                        <h6>What could be the reason behind these points?</h6>
                    </div>
                </div>
            </div>
            <div class="row section-wrap">
                <div class="col-md-4">
                    <span class="count">1</span>
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="crew_dialogue_sheet.data[0].answers.Row1Col1" cols="30" rows="5"
                                placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="crew_dialogue_sheet.data[1].answers.Row1Col2" cols="30" rows="5"
                                placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="crew_dialogue_sheet.data[2].answers.Row1Col3" cols="30" rows="5"
                                placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row section-wrap">
                <div class="col-md-4">
                    <span class="count">2</span>
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="crew_dialogue_sheet.data[0].answers.Row2Col1" cols="30" rows="5"
                                placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="crew_dialogue_sheet.data[1].answers.Row2Col2" cols="30" rows="5"
                                placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="crew_dialogue_sheet.data[2].answers.Row2Col3" cols="30" rows="5"
                                placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row section-wrap">
                <div class="col-md-4">
                    <span class="count">3</span>
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="crew_dialogue_sheet.data[0].answers.Row3Col1" cols="30" rows="5"
                                placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="crew_dialogue_sheet.data[1].answers.Row3Col2" cols="30" rows="5"
                                placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="crew_dialogue_sheet.data[2].answers.Row3Col3" cols="30" rows="5"
                                placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row section-wrap">
                <div class="col-md-4">
                    <span class="count">4</span>
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="crew_dialogue_sheet.data[0].answers.Row4Col1" cols="30" rows="5"
                                placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="crew_dialogue_sheet.data[1].answers.Row4Col2" cols="30" rows="5"
                                placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="crew_dialogue_sheet.data[2].answers.Row4Col3" cols="30" rows="5"
                                placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row section-wrap">
                <div class="col-md-4">
                    <span class="count">5</span>
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="crew_dialogue_sheet.data[0].answers.Row5Col1" cols="30" rows="5"
                                placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="crew_dialogue_sheet.data[1].answers.Row5Col2" cols="30" rows="5"
                                placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="crew_dialogue_sheet.data[2].answers.Row5Col3" cols="30" rows="5"
                                placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row section-wrap">
                <div class="col-md-4">
                    <span class="count">6</span>
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="crew_dialogue_sheet.data[0].answers.Row6Col1" cols="30" rows="5"
                                placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="crew_dialogue_sheet.data[1].answers.Row6Col2" cols="30" rows="5"
                                placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="crew_dialogue_sheet.data[2].answers.Row6Col3" cols="30" rows="5"
                                placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <button *ngIf="!is_update" (click)="saveCrewDialogueSheet()" class="btn btn-primary main-btn">Submit
                Responses</button>
            <button *ngIf="is_update" (click)="updateCrewDialogueSheet()"
                class="btn btn-primary main-btn">Update</button>
        </div>
    </div>
</div>
<!-- Modal -->
<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Sample Crew Dialogue Sheet</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="sample-box">

            <div class="row">
                <div class="col-md-4">
                    <h6>What are the most interesting points in the report?</h6>
                </div>
                <div class="col-md-4">
                    <h6>Why are these points interesting?</h6>
                </div>
                <div class="col-md-4">
                    <h6>What could be the reason behind these points?</h6>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-4">
                    <!-- <span class="side-count">1</span> -->
                    <div class="view-box">
                        <p>Risk management: Overall score per rank</p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="view-box">
                        <p>Big difference between ranks’ perceptions:
                            Seniors are much more positive than Juniors.
                            Why is that?</p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="view-box">
                        <ul>
                            <li>Senior Officers have a managing role</li>
                            <li>Ratings are not participating in paperwork </li>
                            <li>Senior Officers are too critical/Ratings are
                                too positive</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-4">
                    <!-- <span class="side-count">1</span> -->
                    <div class="view-box">
                        <p>Overall score on health and well-being as well
                            as safety leadership</p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="view-box">
                        <p>Interesting that we are so poor in safety
                            leadership and so good in health and well-
                            being.</p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="view-box">
                        <ul>
                            <li>We just got a new PPE stock</li>
                            <li>Shore-based staff is focusing a lot on time
                                pressure
                            </li>
                            <li>Leaders get stressed because of the focus
                                of shore-based staff on rest hours
                            </li>
                            <li>Leaders yell and shout at crew that they need
                                to hurry up</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
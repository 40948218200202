<div class="login-wrap">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="left-wrap">
                    <img src="assets/images/logo.png" class="img img-fluid">
                    <h3>Welcome to Safety Delta</h3>
                    <p>This is where you can access all your learning materials and reports.</p>
                </div>
            </div>
            <div class="col-md-6">
                <form>
                   <div class="form-head">
                    <h4>Check your email</h4>
                    <p>We have already sent the password reset link. Go to your email and click the link.</p>
                   </div>
                    <button class="btn btn-primary main-btn" [routerLink]="['/reset-password']">Got It</button>
                </form>
            </div>
        </div>
    </div>
</div>

<ngx-spinner
bdColor="rgba(0, 0, 0, 0.8)"
size="medium"
color="linear-gradient(45deg, #1a1e1f 0%, #0e6d7c 100%)"
type="square-jelly-box"
[fullScreen]="true"
><p style="color: white">Loading...</p></ngx-spinner>
<div class="login-wrap">
	<div class="container">
		<div class="row">
			<div class="col-md-6">
				<div class="left-wrap">
					<img src="assets/images/logo-new.png" class="img img-fluid">
					<h3>Welcome to Safety Delta</h3>
					<p>This is where you can access all your learning materials and reports.</p>
				</div>
			</div>
			<div class="col-md-6">
				<form [formGroup]="loginForm">
					<div class="form-head">
						<h4>Sign In</h4>
					</div>
					<div class="form-group">
						<input formControlName="email" type="text" class="form-control"
							placeholder="Email address or username">
						<span *ngIf="((loginForm.controls.email.touched) && (loginForm.controls.email.errors?.required))"
							class="text-danger"> Please enter email </span>
						<span *ngIf="((loginForm.controls.email.touched) && (loginForm.controls.email.errors?.pattern))"
							class="text-danger">Please enter valid email</span>
					</div>
					<div class="form-group">
						<input formControlName="password" [type]="passwordType ? 'password' : 'text'"
							class="form-control" placeholder="Password">
						<img (click)="passwordType = !passwordType" src="assets/images/eye.svg" class="img img-fluid toggle-password">
						<span *ngIf="((loginForm.controls.password.touched) && (loginForm.controls.password.errors?.required))"
							class="text-danger">Please enter password</span>
					</div>
					<div *ngIf="isDeactivated">
						<p class="deactivated">This account has been deactivated.</p>
					</div>
					<button class="btn btn-primary main-btn" (click)="login()">Sign In</button>
					<div class="row mt-3">
						<div class="col-md-6">
							<div class="custom-check">
								<input (change)="rememberMe = !rememberMe" type="checkbox" id="remember" [checked]="rememberMe" >
								<label for="remember">Remember Me</label>
							</div>
						</div>
						<div class="col-md-6">
							<a [routerLink]="['/forgot-password']">Forgot Password?</a>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>
import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ReportService } from '../reports/report.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment';
import { NotificationService } from '../notification.service';

@Component({
    selector: 'app-seniour-officer',
    templateUrl: './seniour-officer.component.html',
    styleUrls: ['./seniour-officer.component.scss'],
})
export class SeniourOfficerComponent implements OnInit {
    closeResult = '';
    cycle: any;
    cycle_year: any;
    updated_at: any;
    start_date: any;
    cycle_id: any;
    end_date: any;
    cycle_no: any;
    senior_office_dialogue_sheet: any = {
        dialogue_sheet_type_id: 2,
        data: [
            {
                question:
                    'Does the diagnosis report match with your perception of the current safety culture on board? Why or Why not?',
                answers: {
                    Row5: '',
                },
            },
            {
                question:
                    'Is there any area that the vessel is very good at? Can this expertise be utilized to improve your performance in other safety areas?',
                answers: {
                    Row6: '',
                },
            },
            {
                question:
                    'Based on the diagnosis report, what should you all start doing/do more of? What should you all stop doing?',
                answers: {
                    Row7: '',
                },
            },
            {
                question:
                    'To simulate reflection, write 2-3 questions about diagnosis report that the crew can be asked during the dialogue meeting.',
                answers: {
                    Row8: '',
                },
            },
        ],
    };
    is_update = false;
    is_success_shown = false;

    constructor(
        private reportService: ReportService,
        private modalService: NgbModal,
        private spinner: NgxSpinnerService,
        private notifyService: NotificationService
    ) {}

    ngOnInit(): void {
        this.spinner.show();
        this.cycle = JSON.parse(sessionStorage.getItem('current_cycle_id'));
        this.cycle_year = moment(this.cycle.start_date).format('yyyy');
        this.start_date = moment(this.cycle.start_date).format('LL');
        this.updated_at = moment(this.cycle.updated_at).format('YYYY-MM-DD');
        this.end_date = moment(this.cycle.end_date).format('LL');
        this.cycle_id = this.cycle.id;
        this.cycle_no = this.cycle.cycle_no;
        this.reportService.get_all_dialogue_sheet().subscribe((res: any) => {
            let senior_office_dialogue_sheet = res.data.find(
                (x) => x.dialogue_sheet_type_id === 2
            );;
            if(senior_office_dialogue_sheet){
                this.is_update = true;
                this.senior_office_dialogue_sheet.id =senior_office_dialogue_sheet.id
                this.senior_office_dialogue_sheet.data = senior_office_dialogue_sheet.data
            }
            
            this.spinner.hide();
        });
    }

    open(content) {
        this.modalService
            .open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg' })
            .result.then(
                (result) => {
                    this.closeResult = `Closed with: ${result}`;
                },
                (reason) => {
                    this.closeResult = `Dismissed ${this.getDismissReason(
                        reason
                    )}`;
                }
            );
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    saveSeniorOfficerDialogueSheet() {
        this.spinner.show();
        this.reportService
            .create_dialog_sheet(this.senior_office_dialogue_sheet)
            .subscribe((res: any) => {
                if (res.data) {
                    this.notifyService.showSuccess(
                        'Senior Officer Dialogue Sheet Saved Successfully',
                        'Success'
                    );
                }
                this.spinner.hide();
            });
    }

    updateSeniorOfficerDialogueSheet() {
        this.spinner.show();
        this.reportService
            .update_dialog_sheet(this.senior_office_dialogue_sheet)
            .subscribe((res: any) => {
                if (res.data) {
                    this.notifyService.showSuccess(
                        'Senior Officer Dialogue Sheet Updated Successfully',
                        'Success'
                    );
                }
                this.spinner.hide();
            });
    }
}

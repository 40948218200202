import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SdpFormService } from './sdp-form.service';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { NotificationService } from '../notification.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-sdp-form',
    templateUrl: './sdp-form.component.html',
    styleUrls: ['./sdp-form.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class SdpFormComponent implements OnInit {
    public sdp_data: any = [];
    public safety_area: any = [];
    public cultivator1: any = [];
    public cultivator2: any = [];
    public cultivator3: any = [];
    public subject1: any = [];
    public subject2: any = [];
    public subject3: any = [];
    public activity1: any = [];
    public activity2: any = [];
    public activity3: any = [];
    public isshow: boolean = false;

    cycle:any;
    cycle_year:any; 
    updated_at:any;
    start_date:any;
    cycle_id:any;
    end_date:any;
    cycle_no:any;

    sdpForm: FormGroup;

    dropdownList = [];
    dropdownList2 = [];
    dropdownList3 = [];
    dropdownSettings: IDropdownSettings;
    dropdownSettings2: IDropdownSettings;
    dropdownSettings3: IDropdownSettings;

    submitted = false;
    constructor(
        private sdp_form_service: SdpFormService,
        private formBuilder: FormBuilder,
        private notifyService: NotificationService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.dropdownSettings = {
            singleSelection: false,
            idField: 'item_id',
            textField: 'item_text',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 5,
            limitSelection: 5,
            allowSearchFilter: true,
        };

        this.dropdownSettings2 = {
            singleSelection: false,
            idField: 'item_id',
            textField: 'item_text',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 5,
            limitSelection: 5,
            allowSearchFilter: true,
        };

        this.dropdownSettings3 = {
            singleSelection: false,
            idField: 'item_id',
            textField: 'item_text',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            limitSelection: 5,
            itemsShowLimit: 5,
            allowSearchFilter: true,
        };

        this.sdpForm = this.formBuilder.group({
            safety_area_one: [''],
            cultivator1: [''],
            subject1: [''],
            activity1: [''],
            comments1: [''],
            responsible1: [''],
            target_date1: [''],
            review_date1: [''],
            safety_area_two: [''],
            cultivator2: [''],
            subject2: [''],
            activity2: [''],
            comments2: [''],
            responsible2: [''],
            target_date2: [''],
            review_date2: [''],
            safety_area_three: [''],
            cultivator3: [''],
            subject3: [''],
            activity3: [''],
            comments3: [''],
            responsible3: [''],
            target_date3: [''],
            review_date3: [''],
        });

        this.cycle = JSON.parse(sessionStorage.getItem('current_cycle_id'));
        if (this.cycle) {
            this.cycle_year =  moment(this.cycle.start_date).format('yyyy');
            this.start_date =  moment(this.cycle.start_date).format('LL');
            this.updated_at =  moment(this.cycle.updated_at).format('YYYY-MM-DD');
            this.end_date =  moment(this.cycle.end_date).format('LL');
            this.cycle_id =  this.cycle.id
            this.cycle_no = this.cycle.cycle_no;
        }
        
        this.sdp_form_service.getsdp_form().subscribe((data: any) => {
          let jsonData = JSON.parse(data);
          this.sdp_data = jsonData.data;

          //console.log(this.sdp_data)
          for (let i = 0; i <= jsonData.data.length - 1; i++) {
              this.safety_area.push(jsonData .data[i]);
          }
          this.sdp_form_service.getsdp_recent().subscribe((sdpForm: any) => {
            if(sdpForm){
              this.Select_cultivator(sdpForm.data[0].SafetyArea1,1);
              this.onOptionsSelected(sdpForm.data[0].Cultivator1,1);
              this.ActivitySelected(sdpForm.data[0].Subject1,1);

              this.sdpForm.controls['safety_area_one'].setValue(sdpForm.data[0].SafetyArea1);
              this.sdpForm.controls['cultivator1'].setValue(sdpForm.data[0].Cultivator1);
              this.sdpForm.controls['subject1'].setValue(sdpForm.data[0].Subject1);
              this.sdpForm.controls['activity1'].setValue(this.getValueOfActivities(sdpForm.data[0], "Activity1Row"));
              this.sdpForm.controls['comments1'].setValue(sdpForm.data[0].Comments1);
              this.sdpForm.controls['responsible1'].setValue(sdpForm.data[0].Responsible1);
              this.sdpForm.controls['target_date1'].setValue(moment(sdpForm.data[0].TargetDate1).format('YYYY-MM-DD'));
              this.sdpForm.controls['review_date1'].setValue(moment(sdpForm.data[0].ReviewDate1).format('YYYY-MM-DD'));

              this.Select_cultivator(sdpForm.data[1].SafetyArea2,2);
              this.onOptionsSelected(sdpForm.data[1].Cultivator2,2);
              this.ActivitySelected(sdpForm.data[1].Subject2,2);

              this.sdpForm.controls['safety_area_two'].setValue(sdpForm.data[1].SafetyArea2);
              this.sdpForm.controls['cultivator2'].setValue(sdpForm.data[1].Cultivator2);
              this.sdpForm.controls['subject2'].setValue(sdpForm.data[1].Subject2);
              this.sdpForm.controls['activity2'].setValue(this.getValueOfActivities(sdpForm.data[1], "Activity2Row"));
              this.sdpForm.controls['comments2'].setValue(sdpForm.data[1].Comments2);
              this.sdpForm.controls['responsible2'].setValue(sdpForm.data[1].Responsible2);
              this.sdpForm.controls['target_date2'].setValue(moment(sdpForm.data[1].TargetDate2).format('YYYY-MM-DD'));
              this.sdpForm.controls['review_date2'].setValue(moment(sdpForm.data[1].ReviewDate2).format('YYYY-MM-DD'));

              this.Select_cultivator(sdpForm.data[2].SafetyArea3,3);
              this.onOptionsSelected(sdpForm.data[2].Cultivator3,3);
              this.ActivitySelected(sdpForm.data[2].Subject3,3);
              this.sdpForm.controls['activity3'].setValue(this.getValueOfActivities(sdpForm.data[2], "Activity3Row"));

              this.sdpForm.controls['safety_area_three'].setValue(sdpForm.data[2].SafetyArea3);
              this.sdpForm.controls['cultivator3'].setValue(sdpForm.data[2].Cultivator3);
              this.sdpForm.controls['subject3'].setValue(sdpForm.data[2].Subject3);
              this.sdpForm.controls['comments3'].setValue(sdpForm.data[2].Comments3);
              this.sdpForm.controls['responsible3'].setValue(sdpForm.data[2].Responsible3);
              this.sdpForm.controls['target_date3'].setValue(moment(sdpForm.data[2].TargetDate3).format('YYYY-MM-DD'));
              this.sdpForm.controls['review_date3'].setValue(moment(sdpForm.data[2].ReviewDate3).format('YYYY-MM-DD'));
            }
          });
        });
    }

    get f() {
        return this.sdpForm.controls;
    }

    getValueOfActivities(object, prefix){
      let values = [];
      for (let key in object) {
        if(key.toString().startsWith(prefix)){
          values.push(object[key]);
        }
      }
      return values;
    }

    Select_cultivator($event, index) {
        if (index == 1) {
            this.cultivator1 = [];
            this.subject1 = [];
            this.activity1 = [];

            this.sdpForm.controls['cultivator1'].setValue("");
            this.sdpForm.controls['subject1'].setValue("");
            this.sdpForm.controls['activity1'].setValue([]);

            for (let j = 0; j < this.sdp_data.length; j++) {
              if ($event == this.sdp_data[j].title) {
                  this.cultivator1.push(this.sdp_data[j].CULTIVATOR);
                  break
              }
            }

            this.cultivator1 = this.cultivator1[0];
        } else if (index == 2) {
            this.cultivator2 = [];
            this.subject2 = [];
            this.activity2 = [];

            this.sdpForm.controls['cultivator2'].setValue("");
            this.sdpForm.controls['subject2'].setValue("");
            this.sdpForm.controls['activity2'].setValue([]);

            for (let j = 0; j < this.sdp_data.length; j++) {
              if ($event == this.sdp_data[j].title) {
                  this.cultivator2.push(this.sdp_data[j].CULTIVATOR);
                  break
              }
            }

            this.cultivator2 = this.cultivator2[0];
        } else {
            this.cultivator3 = [];
            this.subject3 = [];
            this.activity3 = [];

            this.sdpForm.controls['cultivator3'].setValue("");
            this.sdpForm.controls['subject3'].setValue("");
            this.sdpForm.controls['activity3'].setValue([]);

            for (let j = 0; j < this.sdp_data.length; j++) {
              if ($event == this.sdp_data[j].title) {
                  this.cultivator3.push(this.sdp_data[j].CULTIVATOR);
                  break
              }
            }

            this.cultivator3 = this.cultivator3[0];
        }
    }

    onOptionsSelected($event, index) {
        // console.log($event)
        if (index == 1) {
            this.subject1 = [];
            this.activity1 = [];

            this.sdpForm.controls['subject1'].setValue("");
            this.sdpForm.controls['activity1'].setValue([]);

            for (let i = 0; i <= this.cultivator1.length - 1; i++) {
                //console.log(this.cultivator1[i].title)
                if ($event == this.cultivator1[i].title) {
                    //console.log('hhhhhh',this.cultivator1[i].subject)
                    this.subject1.push(this.cultivator1[i].subject);
                }
            }

            this.subject1 = this.subject1[0];
            // console.log(this.subject1)
        } else if (index == 2) {
            this.subject2 = [];
            this.activity2 = [];

            this.sdpForm.controls['subject2'].setValue("");
            this.sdpForm.controls['activity2'].setValue([]);

            for (let i = 0; i <= this.cultivator2.length - 1; i++) {
                if ($event == this.cultivator2[i].title) {
                    this.subject2.push(this.cultivator2[i].subject);
                }
            }

            this.subject2 = this.subject2[0];
        } else {
            this.subject3 = [];
            this.activity3 = [];

            this.sdpForm.controls['subject3'].setValue("");
            this.sdpForm.controls['activity3'].setValue([]);

            for (let i = 0; i <= this.cultivator3.length - 1; i++) {
                if ($event == this.cultivator3[i].title) {
                    this.subject3.push(this.cultivator3[i].subject);
                }
            }
            this.subject3 = this.subject3[0];
        }
    }

    ActivitySelected($event, index) {
        if (index == 1) {
            this.activity1 = [];
            
            this.sdpForm.controls['activity1'].setValue([]);

            for (let j = 0; j < this.subject1.length; j++) {
                if (this.subject1[j].title == $event) {
                    this.activity1.push(this.subject1[j].activities);
                }
            }

            this.activity1 = this.activity1[0];
            let new_arr = [];
            for (let i = 0; i < this.activity1.length; i++) {
                new_arr.push({
                    item_id: this.activity1[i],
                    item_text: this.activity1[i],
                });
            }
            this.dropdownList = new_arr;
        } else if (index == 2) {
            this.activity2 = [];

            this.sdpForm.controls['activity2'].setValue([]);

            for (let j = 0; j <= this.subject2.length - 1; j++) {
                if (this.subject2[j].title == $event) {
                    this.activity2.push(this.subject2[j].activities);
                }
            }
            this.activity2 = this.activity2[0];

            let new_arr2 = [];
            //this.dropdownList = this.activity1[0];
            for (let i = 0; i < this.activity2.length; i++) {
                new_arr2.push({
                    item_id: this.activity2[i],
                    item_text: this.activity2[i],
                });
            }
            this.dropdownList2 = new_arr2;
        } else {
            this.activity3 = [];

            this.sdpForm.controls['activity3'].setValue([]);

            for (let j = 0; j <= this.subject3.length - 1; j++) {
                if (this.subject3[j].title == $event) {
                    this.activity3.push(this.subject3[j].activities);
                }
            }
            this.activity3 = this.activity3[0];

            let new_arr3 = [];
            //this.dropdownList = this.activity1[0];
            for (let i = 0; i < this.activity3.length; i++) {
                new_arr3.push({
                    item_id: this.activity3[i],
                    item_text: this.activity3[i],
                });
            }
            this.dropdownList3 = new_arr3;
        }
    }

    // appendedHtml: string = '<div class="form-group"><label>Activities</label><select class="form-control"><option value="1">Select activity</option></select></div>';

    onSubmit() {
        this.submitted = true;
        // console.log('fffffff',this.sdpForm.value);

        let act1 = [];
        for (let i = 0; i < this.sdpForm.value.activity1.length; i++) {
            act1.push(this.sdpForm.value.activity1[i].item_text ? this.sdpForm.value.activity1[i].item_text : this.sdpForm.value.activity1[i]);
        }

        let act2 = [];
        for (let i = 0; i < this.sdpForm.value.activity2.length; i++) {
            act2.push(this.sdpForm.value.activity2[i].item_text ? this.sdpForm.value.activity2[i].item_text : this.sdpForm.value.activity2[i]);
        }

        let act3 = [];
        for (let i = 0; i < this.sdpForm.value.activity3.length; i++) {
            act3.push(this.sdpForm.value.activity3[i].item_text ? this.sdpForm.value.activity3[i].item_text : this.sdpForm.value.activity3[i]);
        }

        if (this.sdpForm.invalid) {
            this.notifyService.showError('Please Fill All Details', 'Error');
            return 'Form Error';
        }

        console.log('fffffff', act1);

        let data = {
            data: [
                {
                    SafetyArea1: this.sdpForm.value.safety_area_one,
                    Cultivator1: this.sdpForm.value.cultivator1,
                    Subject1: this.sdpForm.value.subject1,
                    Activity1Row1: act1[0],
                    Activity1Row2: act1[1],
                    Activity1Row3: act1[2],
                    Activity1Row4: act1[3],
                    Activity1Row5: act1[4],
                    Comments1: this.sdpForm.value.comments1,
                    Responsible1: this.sdpForm.value.responsible1,
                    TargetDate1: moment(this.sdpForm.value.target_date1).format('DD-MMM-YY'),
                    ReviewDate1: moment(this.sdpForm.value.review_date1).format('DD-MMM-YY'),
                },
                {
                    SafetyArea2: this.sdpForm.value.safety_area_two,
                    Cultivator2: this.sdpForm.value.cultivator2,
                    Subject2: this.sdpForm.value.subject2,
                    Activity2Row1: act2[0],
                    Activity2Row2: act2[1],
                    Activity2Row3: act2[2],
                    Activity2Row4: act2[3],
                    Activity2Row5: act2[4],
                    Comments2: this.sdpForm.value.comments2,
                    Responsible2: this.sdpForm.value.responsible2,
                    TargetDate2: moment(this.sdpForm.value.target_date2).format('DD-MMM-YY'),
                    ReviewDate2: moment(this.sdpForm.value.review_date2).format('DD-MMM-YY'),
                },
                {
                    SafetyArea3: this.sdpForm.value.safety_area_three,
                    Cultivator3: this.sdpForm.value.cultivator3,
                    Subject3: this.sdpForm.value.subject3,
                    Activity3Row1: act3[0],
                    Activity3Row2: act3[1],
                    Activity3Row3: act3[2],
                    Activity3Row4: act3[3],
                    Activity3Row5: act3[4],
                    Comments3: this.sdpForm.value.comments3,
                    Responsible3: this.sdpForm.value.responsible3,
                    TargetDate3: moment(this.sdpForm.value.target_date3).format('DD-MMM-YY'),
                    ReviewDate3: moment(this.sdpForm.value.review_date3).format('DD-MMM-YY'),
                },
            ],
        };

        this.sdp_form_service.submitsdp_form(data).subscribe((event) => {
            console.log('post-response:', event);

            this.notifyService.showSuccess(
                'SDP Form Updated Successfully',
                'Success'
            );
        });
    }

    onItemSelect(item: any) {
        // console.log(item);
    }
    onSelectAll(items: any) {
        // console.log(items);
    }

    change(val){
      console.log(val);

    }
}

<div class="search-sidebar" *ngIf="show">
    <h3 class="mb-4">Filter Options</h3>
    <div class="filter-type mb-4">
        <h5 class="sub-head">Role</h5>
        <ul>
            <li *ngFor="let role of roles">
                <div class="custom-check">
                    <input
                        type="checkbox"
                        [value]="role.id"
                        [(ngModel)]="role.isChecked"
                        (change)="search()"
                        [id]="role.id"
                    />
                    <label [for]="role.id">{{ role.value }}</label>
                </div>
            </li>
        </ul>
    </div>
    <div class="lang-type mb-4">
        <h5 class="sub-head">Language</h5>
        <select
            [(ngModel)]="selectedLang"
            class="form-control"
            id="selected-lang"
        >
            <option *ngFor="let local of locales" value="{{ local.code }}">
                {{ local.name }}
            </option>
        </select>
    </div>
</div>
<div
    class="home-wrap search-page"
    [ngClass]="{ 'search-result': show === false }"
>
    <ngx-spinner
        bdColor="rgba(0, 0, 0, 0.8)"
        size="medium"
        color="linear-gradient(45deg, #1a1e1f 0%, #0e6d7c 100%)"
        type="square-jelly-box"
        [fullScreen]="true"
    >
        <p style="color: white">Loading...</p>
    </ngx-spinner>
    <div class="container">
        <div class="search-wrap">
            <div class="row">
                <div class="col-md-10" *ngIf="show">
                    <div class="left-search">
                        <h4>Search</h4>
                        <div class="search-main mb-5">
                            <div class="form-group">
                                <input
                                    type="search"
                                    (keyup)="searchedKeyword$.next($event)"
                                    class="form-control"
                                    placeholder="Search for a title or tags"
                                />
                                <i class="fa fa-search"></i>
                            </div>
                        </div>

                        <div class="select-tags" style="overflow-x: auto">
                            <!--  <div class="scroll-me"> -->
                            <h6>
                                <i class="fa fa-tag" aria-hidden="true"></i>
                                Select Tags
                            </h6>
                            <div
                                class="radio-outer"
                                *ngFor="let item of tags; index as i"
                            >
                                <input
                                    [(ngModel)]="item.isChecked"
                                    [value]="item.value"
                                    type="checkbox"
                                    (change)="search()"
                                    name="tags"
                                />
                                <label> {{ item.value }}</label>
                                <!--  </div> -->
                            </div>
                        </div>

                        <h4 class="page-head">Browse All</h4>

                        <div class="tool-wrap">
                            <div class="row">
                                <div
                                    class="col-md-3"
                                    *ngFor="let item of lessons; index as i"
                                    (click)="
                                        navigateToLesson(
                                            item.id
                                        )
                                    "
                                >
                                    <div class="tool-box">
                                        <div class="tool-img">
                                            <div class="overlay"></div>
                                            <img
                                                [src]="
                                                    item.item.thumbnail_path
                                                "
                                                onerror="this.src='https://green-jakobsen-production.s3-accelerate.amazonaws.com/sitemap/3/images/U6mvZDFJ0ukB9HnCpoT205Ku4PA2iKBJPyA08I2Q.png'"
                                                class="img img-fluid"
                                            />
                                        </div>
                                        <h4>
                                            {{ getTitle(item.item.titles) }}
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

import { Injectable } from '@angular/core';
import {
    CanLoad,
    Route,
    UrlSegment,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    CanActivate,
    Router,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { LoginService } from '../login/login.service';

@Injectable({
    providedIn: 'root',
})
export class RouteGuard implements CanLoad, CanActivate {
    constructor(
        private authService: LoginService,
        private router: Router
    ) {}

    canLoad(
        route: Route,
        segments: UrlSegment[]
    ): Observable<boolean> | Promise<boolean> | boolean {
        return this.authService.authenticated().pipe(
            map((result) => {
                console.log('result', result);
                if (result.authenticated) {
                    return true;
                }
                this.router.navigate(['login']);
                return false;
            })
        );
    }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.authService.authenticated().pipe(
            map((result) => {
                // console.log('result', result);
                if (result.authenticated && result.pinAuthenticated) {
                    return true;
                } else if(result.authenticated && !result.pinAuthenticated){
                    this.router.navigate(['/generate-pin']);
                    return false;
                } else {
                    this.router.navigate(['login']);
                    return false;
                }
            }),
            catchError(() => {
                this.router.navigate(['login']);
                return of(false);
            })
        );
    }
}

import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class LessonService {
    constructor(
        private http: HttpClient,
        @Inject('apiBase') private apiBase: string
    ) {}

    private httpOptions = {
        headers: new HttpHeaders({
            Authorization: 'Bearer ' + sessionStorage.getItem('accessToken'),
        }),
    };
    cycle = JSON.parse(sessionStorage.getItem('current_cycle_id'));
    client_id = JSON.parse(sessionStorage.getItem('client_id'));

    get_lesson_detail(id): Observable<any> {
        return this.http.get(
            this.apiBase + `package-items/${id}?source=web`,
            this.httpOptions
        )
        .map((response:any) => {
          return response;
        })
        .catch(this.handleError);
    }

    handleError(error) {
      let errorArray = [];
      // //window.location.href = "/login";
      // let errorMessage = '';
      // if (error.error instanceof ErrorEvent) {
      //     errorMessage = `Error: ${error.error.message}`;
      // } else {
      //     errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      // }
      // window.alert(errorMessage);
      errorArray.push(error);
      return errorArray;
    }
}

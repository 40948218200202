
<div class="home-wrap">
    <div class="container">
        <div class="sub-head-wrap">
            <div class="row">
                <div class="col-6">
                    <ul class="slide-tool">
                        <li [routerLink]="['/report']"><i class="fa fa-angle-left"></i></li>
                    </ul>
                </div>
            </div>
            <h4>Office Staff Dialogue Sheet</h4>
            <ul class="info">
                <li>Cycle Year: {{cycle_year}}</li>
                <li>Cycle {{cycle_no}}: {{start_date}} - {{end_date}}</li>
            </ul>
            <div class="other-info">
                <div class="row">
                    <div class="col-md-6">
                        <div class="inner-info">
                            <h5 class="header-description">Target User</h5>
                            <p>Office Staff</p>
                        </div>
                        <div class="inner-info">
                            <h5 class="header-description">Purpose</h5>
                            <p>Use the Office Staff dialogue sheet to capture insight on the results and on how various
                                inputs and learning from the industry and other vessels can bring other perspectives on
                                the issues</p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="inner-info">
                            <h5 class="header-description">Instructions</h5>
                            <ol>
                                <li>Read the diagnosis report for a specific vessel.</li>
                                <li>Complete the table below (individually or in a group).</li>
                                <li>Schedule a dialogue with the shipboard management of the vessel.</li>
                            </ol>
                        </div>
                        <div class="inner-info">
                            <h5 class="header-description">Remember…</h5>
                            <ul>
                                <li>to make each interesting point refer to a specific safety area, cultivator, or
                                    safety behaviour in the diagnosis report. This will help develop the Safety Delta
                                    Development Plan (SDP)</li>
                                <li>to avoid jumping to conclusions and be open to crew’s input and ideas</li>
                                <li>to focus on long-term development rather than on short-term error-finding missions
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <button class="btn btn-primary main-btn sec-btn" (click)="open(content)">View Sample</button>
        </div>

        <div class="output-wrap">
            <div class="output-head">
                <div class="row">
                    <div class="col-md-3">
                        <h6>Interesting points to work on
                            (include reference to diagnosis report)</h6>
                    </div>
                    <div class="col-md-3">
                        <h6>Possible causes of these points</h6>
                    </div>
                    <div class="col-md-3">
                        <h6>Inputs or learning from the industry or
                            other vessels?</h6>
                    </div>
                    <div class="col-md-3">
                        <h6>Things crew members/office staff can do to address these points</h6>
                    </div>
                </div>
            </div>
            <div class="row section-wrap">
                <div class="col-md-3">
                    <span class="count">1</span>
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="office_staff_dialogue_sheet.data[0].answers.Row1Col1" cols="30"
                                rows="5" placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="office_staff_dialogue_sheet.data[1].answers.Row1Col2" cols="30"
                                rows="5" placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="office_staff_dialogue_sheet.data[2].answers.Row1Col3" id="" cols="30"
                                rows="5" placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="office_staff_dialogue_sheet.data[3].answers.Row1Col4" id="" cols="30"
                                rows="5" placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row section-wrap">
                <div class="col-md-3">
                    <span class="count">2</span>
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="office_staff_dialogue_sheet.data[0].answers.Row2Col1" cols="30"
                                rows="5" placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="office_staff_dialogue_sheet.data[1].answers.Row2Col2" rows="5"
                                placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="office_staff_dialogue_sheet.data[2].answers.Row2Col3" cols="30"
                                rows="5" placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="office_staff_dialogue_sheet.data[3].answers.Row2Col4" id="" cols="30"
                                rows="5" placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row section-wrap">
                <div class="col-md-3">
                    <span class="count">3</span>
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="office_staff_dialogue_sheet.data[0].answers.Row3Col1" cols="30"
                                rows="5" placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="office_staff_dialogue_sheet.data[1].answers.Row3Col2" cols="30"
                                rows="5" placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="office_staff_dialogue_sheet.data[2].answers.Row3Col3" cols="30"
                                rows="5" placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="office_staff_dialogue_sheet.data[3].answers.Row3Col4" id="" cols="30"
                                rows="5" placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row section-wrap">
                <div class="col-md-3">
                    <span class="count">4</span>
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="office_staff_dialogue_sheet.data[0].answers.Row4Col1" cols="30"
                                rows="5" placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="office_staff_dialogue_sheet.data[1].answers.Row4Col2" cols="30"
                                rows="5" placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="office_staff_dialogue_sheet.data[2].answers.Row4Col3" cols="30"
                                rows="5" placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="office_staff_dialogue_sheet.data[3].answers.Row4Col4" id="" cols="30"
                                rows="5" placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row section-wrap">
                <div class="col-md-3">
                    <span class="count">5</span>
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="office_staff_dialogue_sheet.data[0].answers.Row5Col1" cols="30"
                                rows="5" placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="office_staff_dialogue_sheet.data[1].answers.Row5Col2" cols="30"
                                rows="5" placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="office_staff_dialogue_sheet.data[2].answers.Row5Col3" cols="30"
                                rows="5" placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="office_staff_dialogue_sheet.data[3].answers.Row5Col4" id="" cols="30"
                                rows="5" placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row section-wrap">
                <div class="col-md-3">
                    <span class="count">6</span>
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="office_staff_dialogue_sheet.data[0].answers.Row6Col1" cols="30"
                                rows="5" placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="office_staff_dialogue_sheet.data[1].answers.Row6Col2" cols="30"
                                rows="5" placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="office_staff_dialogue_sheet.data[2].answers.Row6Col3" cols="30"
                                rows="5" placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="office_staff_dialogue_sheet.data[3].answers.Row6Col4" id="" cols="30"
                                rows="5" placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row section-wrap">
                <div class="col-md-3">
                    <span class="count">7</span>
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="office_staff_dialogue_sheet.data[0].answers.Row7Col1" cols="30"
                                rows="5" placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="office_staff_dialogue_sheet.data[1].answers.Row7Col2" cols="30"
                                rows="5" placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="office_staff_dialogue_sheet.data[2].answers.Row7Col3" cols="30"
                                rows="5" placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="office_staff_dialogue_sheet.data[3].answers.Row7Col4" id="" cols="30"
                                rows="5" placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row section-wrap">
                <div class="col-md-3">
                    <span class="count">8</span>
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="office_staff_dialogue_sheet.data[0].answers.Row8Col1" cols="30"
                                rows="5" placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="office_staff_dialogue_sheet.data[1].answers.Row8Col2" cols="30"
                                rows="5" placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="office_staff_dialogue_sheet.data[2].answers.Row8Col3" cols="30"
                                rows="5" placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="office_staff_dialogue_sheet.data[3].answers.Row8Col4" id="" cols="30"
                                rows="5" placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row section-wrap">
                <div class="col-md-3">
                    <span class="count">9</span>
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="office_staff_dialogue_sheet.data[0].answers.Row9Col1" cols="30"
                                rows="5" placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="office_staff_dialogue_sheet.data[1].answers.Row9Col2" cols="30"
                                rows="5" placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="office_staff_dialogue_sheet.data[2].answers.Row9Col3" cols="30"
                                rows="5" placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="office_staff_dialogue_sheet.data[3].answers.Row9Col4" id="" cols="30"
                                rows="5" placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row section-wrap">
                <div class="col-md-3">
                    <span class="count">10</span>
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="office_staff_dialogue_sheet.data[0].answers.Row10Col1" cols="30"
                                rows="5" placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="office_staff_dialogue_sheet.data[1].answers.Row10Col2" cols="30"
                                rows="5" placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="office_staff_dialogue_sheet.data[2].answers.Row10Col3" cols="30"
                                rows="5" placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="office_staff_dialogue_sheet.data[3].answers.Row10Col4" id=""
                                cols="30" rows="5" placeholder="Enter your response here"
                                class="form-control"></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <button *ngIf="!is_update" (click)="saveOfficeDialogueSheet()" class="btn btn-primary main-btn">Submit
                Responses</button>
            <button *ngIf="is_update" (click)="updateOfficeDialogueSheet()"
                class="btn btn-primary main-btn">Update</button>
        </div>
    </div>
</div>
<!-- Modal -->
<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Sample Office Staff Dialogue Sheet</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="sample-box">

            <div class="row">
                <div class="col-md-3">
                    <h6>Interesting points to work on (include reference to diagnosis report)</h6>
                </div>
                <div class="col-md-3">
                    <h6>Possible causes of these points</h6>
                </div>
                <div class="col-md-3">
                    <h6>Inputs or learning from the industry or other vessels</h6>
                </div>
                <div class="col-md-3">
                    <h6>Things crew members/ Office Staff can do to address these points</h6>
                </div>
            </div>
            <div class="row mb-6">
                <div class="col-md-3">
                    <!-- <span class="side-count">1</span> -->
                    <div class="view-box">
                        <p>The score is lower on Risk Management during execution of the job.</p>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="view-box">
                        <ul>
                            <li> Poor communication during the job </li>
                            <li> Toolbox talks are rarely held. </li>
                            <li> Crew members are not involved in the toolbox talk. </li>
                            <li> Team members are rarely stopped when they do an unsafe act. </li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="view-box">
                        <ul>
                            <li>Last year, Vessel XX had good results turning the toolbox talk from one-way (giving
                                instructions only) to two-way dialogue.</li>
                            <li>Job leader should ask questions to involve and engage all crew members</li>
                            <li>Inspiration from industry article about the hierarchy on board being a barrier for a
                                proactive intervention culture. </li>

                        </ul>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="view-box">
                        <ul>
                            <li>Conduct a toolbox talk before every job.</li>
                            <li>Job leader should ask questions to involve and engage all crew members. </li>
                            <li>Job leader should encourage all crew members to ask questions. </li>
                            <li>Onboard training for all crew members about conducting toolbox talks. </li>
                        </ul>
                    </div>
                </div>
            </div>

        </div>
    </div>
</ng-template>
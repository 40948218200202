import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SdpFormService {
    constructor(
        private http: HttpClient,
        @Inject('apiBase') private apiBase: string
    ) {}

    getsdp_form() {
        const httpOptions = {
            headers: new HttpHeaders({
                Authorization:
                    'Bearer ' + sessionStorage.getItem('accessToken'),
            }),
            responseType: 'text' as 'json'
        };

        return this.http.get(
            `${this.apiBase}config/sdp-config-file`,
            httpOptions
        );
    }

    getsdp_recent() {
        const current_id = JSON.parse(
            sessionStorage.getItem('current_cycle_id')
        );
        const id = current_id.id;
        const httpOptions = {
            headers: new HttpHeaders({
                Authorization:
                    'Bearer ' + sessionStorage.getItem('accessToken'),
            }),
        };

        return this.http.get(
            `${this.apiBase}reports/${id}/development-plan`,
            httpOptions
        );
    }

    submitsdp_form(data) {
        const current_id = JSON.parse(
            sessionStorage.getItem('current_cycle_id')
        );
        const id = current_id.id;
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + sessionStorage.getItem('accessToken'),
          })
        };

        return this.http.post(
            `${this.apiBase}reports/${id}/development-plan`,data,
            httpOptions
        );
    }
}

import { Injectable, Inject } from '@angular/core';
import { HttpClient,HttpHeaders  } from '@angular/common/http';
import { Observable, of } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ResetPasswordService {

       constructor(
        private http: HttpClient,
        @Inject('apiBase') private apiBase: string
    ) {}


       ResetUserPass(form){
         return this.http.post(`${this.apiBase}auth/password/reset?email=`+form.email+'&password='+form.password+'&password_confirmation='+form.password_confirmation+'&token='+form.token,{});

       // return this.http.post(`${this.apiBase}auth/password/reset`+form);
        
         }




}


<div class="home-wrap">
    <div class="container">
        <div class="sub-head-wrap">
            <div class="row">
                <div class="col-6">
                    <ul class="slide-tool">
                        <li [routerLink]="['/report']"><i class="fa fa-angle-left"></i></li>
                    </ul>
                </div>
            </div>
            <h4>Senior Officer Dialogue Sheet</h4>
            <ul class="info">
                <li>Cycle Year: {{cycle_year}}</li>
                <li>Cycle {{cycle_no}}: {{start_date}} - {{end_date}}</li>
            </ul>
            <div class="other-info">
                <div class="row">
                    <div class="col-md-6">
                        <div class="inner-info">
                            <h5 class="header-description">Target User</h5>
                            <p>Senior Officer</p>
                        </div>
                        <div class="inner-info">
                            <h5 class="header-description">Aim</h5>
                            <p>The aim of the Senior officer dialogue sheet is for senior officers to reflect upon the
                                diagnosis report and identify points for development. It also guides the Senior Officers
                                on how to facilitate the crew dialogue meeting in a proper and open manner.</p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="inner-info">
                            <h5 class="header-description">Instructions</h5>
                            <ol>
                                <li>Read the diagnosis report and complete the Crew dialogue sheet.</li>
                                <li> 
                                    To be prepared for the dialogue, write in the below table your reflections on the diagnosis report. You may do this individually or by groups.
                                </li>
                                <li>Schedule a crew dialogue meeting (approx. one hour in duration) that most of the
                                    crew members can attend.</li>
                                <li>Select a Senior Officer who will facilitate the crew dialogue meeting. if you are
                                    selected as the facilitator, click view facilitator's notes.</li>
                            </ol>
                        </div>
                        <!--   <div class="inner-info">
                            <h5>Remember…</h5>
                            <ul>
                                <li>to make each interesting point refer to a specific safety area, cultivator, or safety behaviour in the diagnosis report. This will help develop the Safety Delta Development Plan (SDP)</li>
                                <li>to avoid jumping toc onclusions and be open to crew’s input and ideas</li>
                                <li>to focus on long-term development rather than on short-term error- finding missions</li>
                            </ul>
                        </div> -->
                    </div>
                </div>
            </div>
            <button class="btn btn-primary main-btn sec-btn" (click)="open(content)">View Facilitator’s Notes</button>
        </div>

        <div class="output-wrap">
            <div class="output-head">
                <div class="row">
                    <div class="col-md-12">
                        <h6>1. Does the diagnosis report match with your perception of the current safety culture on
                            board? Why or why not?</h6>
                    </div>


                </div>
                <div class="row section-wrap">
                    <div class="col-md-12">

                        <div class="bottom-wrap">
                            <div class="form-group">
                                <textarea [(ngModel)]="senior_office_dialogue_sheet.data[0].answers.Row5" name="" id=""
                                    cols="30" rows="5" placeholder="Enter your response here"
                                    class="form-control"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="output-head">
                <div class="row">
                    <div class="col-md-12">
                        <h6>2. Is there any area that the vessel is very good at? Can this expertise be utilised to
                            improve your performance in other safety areas?</h6>
                    </div>
                </div>
                <div class="row section-wrap">
                    <div class="col-md-12">

                        <div class="bottom-wrap">
                            <div class="form-group">
                                <textarea [(ngModel)]="senior_office_dialogue_sheet.data[1].answers.Row6" name="" id=""
                                    cols="30" rows="5" placeholder="Enter your response here"
                                    class="form-control"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="output-head">
                <div class="row">
                    <div class="col-md-12">
                        <h6>3. Based on the diagnosis report, what should you all start doing/do more of? What should
                            you all stop doing?</h6>
                    </div>
                </div>
                <div class="row section-wrap">
                    <div class="col-md-12">

                        <div class="bottom-wrap">
                            <div class="form-group">
                                <textarea [(ngModel)]="senior_office_dialogue_sheet.data[2].answers.Row7" name="" id=""
                                    cols="30" rows="5" placeholder="Enter your response here"
                                    class="form-control"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="output-head">
                <div class="row">
                    <div class="col-md-12">
                        <h6>4. To stimulate reflection, write 2–3 questions about the diagnosis report that the crew
                            can be asked during the dialogue meeting.</h6>
                    </div>
                </div>
                <div class="row section-wrap">
                    <div class="col-md-12">

                        <div class="bottom-wrap">
                            <div class="form-group">
                                <textarea [(ngModel)]="senior_office_dialogue_sheet.data[3].answers.Row8" name="" id=""
                                    cols="30" rows="5" placeholder="Enter your response here"
                                    class="form-control"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <button *ngIf="!is_update" (click)="saveSeniorOfficerDialogueSheet()"
                class="btn btn-primary main-btn">Submit Responses</button>
            <button *ngIf="is_update" (click)="updateSeniorOfficerDialogueSheet()"
                class="btn btn-primary main-btn">Update</button>
        </div>
    </div>
</div>
<!-- Modal -->
<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Facilitator (For senior officers who will facilitate the
            dialogue)</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="sample-box">
            <div class="row">
                <div class="col-md-4">
                    <h6>Agenda</h6>
                </div>
                <div class="col-md-8">
                    <h6>Facilitator's Notes</h6>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-4">
                    <!-- <span class="side-count">1</span> -->
                    <div class="view-box">
                        <p>Welcome the participants.</p>
                    </div>
                </div>
                <div class="col-md-8">
                    <div class="view-box">
                        <li> Before the meeting, appoint a Senior Officer to take notes during the meeting (the 
                            Dialogue sheet: ‘Senior officer compiled notes’ can be helpful in relation to this task) </li>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-4">
                    <!-- <span class="side-count">1</span> -->
                    <div class="view-box">
                        <p>Crew give inputs on what they think are the
                            most interesting points in the report.</p>
                    </div>
                </div>
                <div class="col-md-8">
                    <div class="view-box">
                        <li> If crew have completed the ‘Crew dialogue sheet’ ask them to present their answers. </li>
                        <li> Ensure that everyone listens to the speaker and that the speaker feels free to express his/her ideas </li>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-4">
                    <!-- <span class="side-count">1</span> -->
                    <div class="view-box">
                        <p>Crew discuss inputs and exchange thoughts
                            and ideas.</p>
                    </div>
                </div>
                <div class="col-md-8">
                    <div class="view-box">
                        <li>  Ask clarifying questions on the points raised. Focus first on issues that are raised by several
                            individuals/groups. </li>
                        <li>It is recommended to stimulate reflections by asking the questions in the ‘Senior officer
                            dialogue sheet’ table (items 1-3 and senior officers question in item 4).</li>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-4">
                    <!-- <span class="side-count">1</span> -->
                    <div class="view-box">
                        <p>Sum up 3-5 major points that were raised.</p>
                    </div>
                </div>
                <div class="col-md-8">
                    <div class="view-box">
                        <li> The note taker notes down interesting points and improvement ideas (the Dialogue sheet:
                            ‘Senior officer compiled notes’ can be helpful in relation to this task). </li>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-4">
                    <!-- <span class="side-count">1</span> -->
                    <div class="view-box">
                        <p>End meeting.</p>
                    </div>
                </div>
                <div class="col-md-8">
                    <div class="view-box">
                        <li> Thank the participants for joining the dialogue. </li>
                    </div>
                </div>
            </div>

        </div>
    </div>
</ng-template>
<div class="home-wrap small-box">
    <div class="container">
        <h4 class="page-head">{{ quick_access.name }}</h4>
        <div class="tool-wrap">
            <div style="text-align: right;">
                <button class="btn btn-primary main-btn" (click)="getrecent()"
                type="button"><i class="fa fa-refresh"></i></button>
            </div>
            <div class="row quick-access">
                <div
                    class="col-sm-2"
                    *ngFor="let item of quick_access.items; index as i"
                    (click)="navigateToLesson(item.id)"
                >
                    <div class="tool-box-small">
                        <img
                            src="{{ item.item.thumbnail_path }}"
                            class="img img-fluid"
                        />
                    </div>
                    <h4>{{ getTitle(item.item.titles)}}</h4>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner
    bdColor="rgba(0, 0, 0, 0.8)"
    size="medium"
    color="linear-gradient(45deg, #1a1e1f 0%, #0e6d7c 100%)"
    type="square-jelly-box"
    [fullScreen]="true"
    ><p style="color: white">Loading...</p></ngx-spinner
>

<div class="home-wrap">
    <div class="container">
        <h4 class="page-head">{{ process_tools.name }}</h4>
        <div class="tool-wrap">
            <div class="row">
                <div
                    class="col-md-2"
                    *ngFor="let item of process_tools.items; index as i"
                    (click)="navigateToLesson(item.id)"
                >
                    <div class="tool-box">
                        <div class="tool-img">
                            <div class="overlay"></div>
                            <img
                                [src]="item.item.thumbnail_path"
                                onerror="this.src='https://green-jakobsen-production.s3-accelerate.amazonaws.com/sitemap/3/images/U6mvZDFJ0ukB9HnCpoT205Ku4PA2iKBJPyA08I2Q.png'"
                                class="img img-fluid"
                            />
                        </div>
                        <h4>{{ getTitle(item.item.titles) }}</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

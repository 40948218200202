
<div class="home-wrap">
    <div *ngIf="cycle" class="container">
        <div class="sub-head-wrap">
            <div class="row">
                <div class="col-6">
                    <ul class="slide-tool">
                        <li [routerLink]="['/home']"><i class="fa fa-angle-left"></i></li>
                    </ul>
                </div>
            </div>
            <h4>Cycle Status</h4>
            <div class="row">
                <div class="col-sm-10 offset-sm-1">
                    <div class="row">
                        <div class="col-sm-3 mb-3">
                            <span>
                                Diagnosis
                            </span>
                        </div>
                        <div class="col-sm-9 mb-3">
                            <div class="btn-group" role="group" aria-label="Basic example">
                                <button (click)="updateCycle(0, 'inactive')" [ngClass]="{ 'activ': diagnosis_status == 'inactive'}" type="button" class="btn btn-secondary">Inactive</button>
                                <button (click)="updateCycle(0, 'in_progress')" [ngClass]="{ 'activ': diagnosis_status == 'in_progress'}" type="button" class="btn btn-secondary">In-Progress</button>
                                <button (click)="updateCycle(0, 'completed')" [ngClass]="{ 'activ': diagnosis_status == 'completed'}" type="button" class="btn btn-secondary">Completed</button>
                            </div>
                        </div>
                        <div class="col-sm-3 mb-3">
                            <span>
                                Dialogue
                            </span>
                        </div>
                        <div class="col-sm-9 mb-3">
                            <div class="btn-group" role="group" aria-label="Basic example">
                                <button (click)="updateCycle(1, 'inactive')" [ngClass]="{ 'activ': dialogue_status == 'inactive'}" type="button" class="btn btn-secondary">Inactive</button>
                                <button (click)="updateCycle(1, 'in_progress')" [ngClass]="{ 'activ': dialogue_status == 'in_progress'}" type="button" class="btn btn-secondary">In-Progress</button>
                                <button (click)="updateCycle(1, 'completed')" [ngClass]="{ 'activ': dialogue_status == 'completed'}" type="button" class="btn btn-secondary">Completed</button>
                            </div>
                        </div>
                        <div class="col-sm-3 mb-3">
                            <span>
                                Development
                            </span>
                        </div>
                        <div class="col-sm-9 mb-3">
                            <div class="btn-group" role="group" aria-label="Basic example">
                                <button (click)="updateCycle(2, 'inactive')" [ngClass]="{ 'activ': development_status == 'inactive'}" type="button" class="btn btn-secondary">Inactive</button>
                                <button (click)="updateCycle(2, 'in_progress')" [ngClass]="{ 'activ': development_status == 'in_progress'}" type="button" class="btn btn-secondary">In-Progress</button>
                                <button (click)="updateCycle(2, 'completed')" [ngClass]="{ 'activ': development_status == 'completed'}" type="button" class="btn btn-secondary">Completed</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!cycle" class="container">
        <div class="row">
            <div class="col-sm-6 offset-sm-3">
                <h1>No Cycle Available</h1>
            </div>
        </div>
    </div>
</div>



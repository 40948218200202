
<div class="home-wrap">
    <div class="container">
        <div class="sub-head-wrap">
            <div class="row">
                <div class="col-6">
                    <ul class="slide-tool">
                        <li [routerLink]="['/report']"><i class="fa fa-angle-left"></i></li>
                    </ul>
                </div>
            </div>
            <h4>Senior Officer Compiled Notes</h4>
            <ul class="info">
                <li>Cycle Year: {{cycle_year}}</li>
                <li>Cycle {{cycle_no}}: {{start_date}} - {{end_date}}</li>
            </ul>
            <div class="other-info">
                <div class="row">
                    <div class="col-md-12">
                        <div class="inner-info">
                            <h5>Date of Meeting:</h5>
                            <div class="row">
                                <div class="col-md-3 col-sm-12">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <input class="form-control" placeholder="yyyy-mm-dd" name="dp"
                                                [(ngModel)]="meeting_date" ngbDatepicker #d="ngbDatepicker">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar" (click)="d.toggle()"
                                                    type="button"></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="inner-info">
                            <h5 class="header-description">Target User</h5>
                            <p>Appointed note taker (Senior Officer)</p>
                        </div>
                        <div class="inner-info">
                            <h5 class="header-description">Aim</h5>
                            <p>The aim of the Senior Officer compiled notes is to capture points for further
                                development.</p>

                            <p>This document should also serve as the basis for the dialogue with the office staff, and
                                as the first step in developing the Safety Delta Development Plan (SDP).</p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="inner-info">
                            <h5 class="header-description">Instructions</h5>
                            <p>Complete the table based on the exchange of ideas during the dialogue.</p>
                        </div>
                        <div class="inner-info">
                            <h5 class="header-description">Remember…</h5>
                            <p>to make each interesting point refer to a specific safety area, cultivator, or
                                safety behaviour in the diagnosis report. This will help develop the Safety Delta
                                Development Plan (SDP).</p>
                        </div>
                    </div>
                </div>
            </div>
            <button class="btn btn-primary main-btn sec-btn" (click)="open(content)">View Sample</button>
        </div>

        <div class="output-wrap">
            <div class="output-head">
                <div class="row">
                    <div class="col-md-4">
                        <h6>Interesting points to work on (include reference to diagnosis report)</h6>
                    </div>
                    <div class="col-md-4">
                        <h6>Possible causes of these points</h6>
                    </div>
                    <div class="col-md-4">
                        <h6>Possible causes of these points</h6>
                    </div>
                </div>
            </div>
            <div class="row section-wrap">
                <div class="col-md-4">
                    <span class="count">1</span>
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="senior_compiled_note.data[0].answers.Row1Col1" cols="30" rows="5"
                                placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="senior_compiled_note.data[1].answers.Row1Col2" cols="30" rows="5"
                                placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="senior_compiled_note.data[2].answers.Row1Col3" id="" cols="30"
                                rows="5" placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row section-wrap">
                <div class="col-md-4">
                    <span class="count">2</span>
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="senior_compiled_note.data[0].answers.Row2Col1" cols="30" rows="5"
                                placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="senior_compiled_note.data[1].answers.Row2Col2" rows="5"
                                placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="senior_compiled_note.data[2].answers.Row2Col3" cols="30" rows="5"
                                placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row section-wrap">
                <div class="col-md-4">
                    <span class="count">3</span>
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="senior_compiled_note.data[0].answers.Row3Col1" cols="30" rows="5"
                                placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="senior_compiled_note.data[1].answers.Row3Col2" cols="30" rows="5"
                                placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="senior_compiled_note.data[2].answers.Row3Col3" cols="30" rows="5"
                                placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row section-wrap">
                <div class="col-md-4">
                    <span class="count">4</span>
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="senior_compiled_note.data[0].answers.Row4Col1" cols="30" rows="5"
                                placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="senior_compiled_note.data[1].answers.Row4Col2" cols="30" rows="5"
                                placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="senior_compiled_note.data[2].answers.Row4Col3" cols="30" rows="5"
                                placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row section-wrap">
                <div class="col-md-4">
                    <span class="count">5</span>
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="senior_compiled_note.data[0].answers.Row5Col1" cols="30" rows="5"
                                placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="senior_compiled_note.data[1].answers.Row5Col2" cols="30" rows="5"
                                placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="senior_compiled_note.data[2].answers.Row5Col3" cols="30" rows="5"
                                placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row section-wrap">
                <div class="col-md-4">
                    <span class="count">6</span>
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="senior_compiled_note.data[0].answers.Row6Col1" cols="30" rows="5"
                                placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="senior_compiled_note.data[1].answers.Row6Col2" cols="30" rows="5"
                                placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="senior_compiled_note.data[2].answers.Row6Col3" cols="30" rows="5"
                                placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row section-wrap">
                <div class="col-md-4">
                    <span class="count">7</span>
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="senior_compiled_note.data[0].answers.Row7Col1" cols="30" rows="5"
                                placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="senior_compiled_note.data[1].answers.Row7Col2" cols="30" rows="5"
                                placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="senior_compiled_note.data[2].answers.Row7Col3" cols="30" rows="5"
                                placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row section-wrap">
                <div class="col-md-4">
                    <span class="count">8</span>
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="senior_compiled_note.data[0].answers.Row8Col1" cols="30" rows="5"
                                placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="senior_compiled_note.data[1].answers.Row8Col2" cols="30" rows="5"
                                placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="senior_compiled_note.data[2].answers.Row8Col3" cols="30" rows="5"
                                placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row section-wrap">
                <div class="col-md-4">
                    <span class="count">9</span>
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="senior_compiled_note.data[0].answers.Row9Col1" cols="30" rows="5"
                                placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="senior_compiled_note.data[1].answers.Row9Col2" cols="30" rows="5"
                                placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="senior_compiled_note.data[2].answers.Row9Col3" cols="30" rows="5"
                                placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row section-wrap">
                <div class="col-md-4">
                    <span class="count">10</span>
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="senior_compiled_note.data[0].answers.Row10Col1" cols="30" rows="5"
                                placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="senior_compiled_note.data[1].answers.Row10Col2" cols="30" rows="5"
                                placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="bottom-wrap">
                        <div class="form-group">
                            <textarea [(ngModel)]="senior_compiled_note.data[2].answers.Row10Col3" cols="30" rows="5"
                                placeholder="Enter your response here" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <button *ngIf="!is_update" (click)="saveSeniorOfficerCompiledNote()" class="btn btn-primary main-btn">Submit
                Responses</button>
            <button *ngIf="is_update" (click)="updateSeniorOfficerCompiledNote()"
                class="btn btn-primary main-btn">Update</button>
            <div class="row mt-4">
                <div class="col-md-4 offset-md-4">
                    <ngb-toast *ngIf="is_success_shown" [delay]="5000" [autohide]="true"
                        (hide)="is_success_shown = false;" class="status-message">
                        <div>
                            <p class="text-center">Report Updated Successfully!</p>
                        </div>
                    </ngb-toast>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Modal -->
<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Facilitator Notes</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="sample-box">

            <div class="row">
                <div class="col-md-4">
                    <h6>Interesting points to work on (include reference to diagnosis report)</h6>
                </div>
                <div class="col-md-4">
                    <h6>Possible causes of these points</h6>
                </div>
                <div class="col-md-4">
                    <h6>Things we can do to address these points</h6>
                </div>
            </div>
            <div class="row mb-6">
                <div class="col-md-4">
                    <!-- <span class="side-count">1</span> -->
                    <div class="view-box">
                        <p>Often crew don’t see the value of toolbox talk
                            (cultivator: Risk management in the execution
                            of a job).</p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="view-box">
                        <ul>
                            <li> Toolbox talks are rarely held </li>
                            <li> There is no mutual communication. </li>
                            <li> Crew members are not involved in the toolbox talks. </li>
                            <li> Crew members don’t ask any questions. </li>
                            <li> Team members are rarely stopped when they are doing an unsafe act. </li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="view-box">
                        <ul>
                            <li>Conduct toolbox talk before every job.</li>
                            <li>Job leader should ask questions to involve
                                and engage all crew members. </li>
                            <li>Job leader should encourage all crew
                                members to ask questions.</li>
                            <li>Onboard training for all crew members
                                about conducting toolbox talks.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

